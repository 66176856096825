<template>
  <div class="modal-backdrop" @click="closeInfoModal">
    <div class="modal-content" @click.stop>
      <h3 class="title">{{ selectedFolder.name }}</h3>
      <div
        v-html="selectedFolder.text || '<p>Aucune description disponible</p>'"
      ></div>
      <button @click="closeInfoModal">Fermer</button>
    </div>
  </div>
</template>
<script>
export default {
    name:"PopUp",
    props: {
        selectedFolder: {
            type: Object,
            required: true,
        },
    },
    methods: {
        closeInfoModal() {
            this.$emit("close");
        },
    },
}
</script>
<style scoped>
/* Contexte sombre et flouté pour la modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6); /* Fond sombre légèrement plus opaque */
  backdrop-filter: blur(4px); /* Floutage de l'arrière-plan */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding: 10px; /* Espace pour éviter que la modale touche les bords sur petits écrans */
}

/* Contenu de la modal */
.modal-content {
  background-color: #fefefe; /* Blanc cassé pour un effet plus doux */
  padding: 25px;
  border-radius: 15px; /* Coins plus arrondis */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); /* Ombre douce et diffuse */
  max-width: 650px; /* Largeur un peu plus large pour plus de confort visuel */
  width: 100%;
  max-height: 85vh; /* Limiter la hauteur pour éviter le débordement */
  overflow-y: auto; /* Scroll si le contenu dépasse */
  text-align: center;
  transition: all 0.3s ease-in-out; /* Transition fluide pour les interactions */
}

/* Titre de la modal */
.modal-content h3 {
  margin-bottom: 15px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #239380; /* Couleur douce et moderne */
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

/* Texte de la modal */
.modal-content p {
  margin-bottom: 20px;
  font-size: 1rem;
  color: #555; /* Couleur légèrement assombrie pour un meilleur contraste */
  line-height: 1.6; /* Espacement plus aéré entre les lignes */
}

/* Bouton stylisé */
.modal-content button {
  padding: 12px 24px;
  background-color: #239380;
  color: white;
  border: none;
  border-radius: 25px; /* Bouton avec bords arrondis */
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Légère ombre pour donner de la profondeur */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Transition douce */
  margin-top: 15px;
}

/* Effet au survol du bouton */
.modal-content button:hover {
  background-color: #348b7d; /* Teinte plus sombre au survol */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); /* Ombre plus prononcée */
}

/* Effet au clic du bouton */
.modal-content button:active {
  background-color: #346e62; /* Teinte encore plus sombre au clic */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Ombre réduite au clic */
  transform: scale(0.98); /* Léger effet de réduction au clic */
}

/* Media Queries pour un affichage fluide sur tous les appareils */
@media (max-width: 768px) {
  .modal-content {
    max-width: 95vw; /* Réduire la largeur sur les tablettes */
    padding: 20px; /* Réduire le padding pour les petits écrans */
  }

  .modal-content h3 {
    font-size: 1.6rem; /* Ajuster la taille du titre */
  }

  .modal-content p {
    font-size: 0.95rem; /* Ajuster la taille du texte */
  }

  .modal-content button {
    font-size: 0.9rem;
    padding: 10px 20px; /* Réduire légèrement les dimensions du bouton */
  }
}

@media (max-width: 480px) {
  .modal-content {
    max-width: 100vw; /* Prendre toute la largeur sur les très petits écrans */
    max-height: 80vh; /* Limiter la hauteur sur les petits écrans */
    padding: 15px; /* Réduire le padding pour les très petits écrans */
  }

  .modal-content h3 {
    font-size: 1.4rem; /* Titre légèrement plus petit */
  }

  .modal-content p {
    font-size: 0.9rem; /* Texte légèrement plus petit */
  }

  .modal-content button {
    font-size: 0.85rem;
    padding: 8px 16px; /* Bouton plus petit */
  }
}
</style>
