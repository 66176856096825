<template>
  <div class="form-container" v-if="editableUser">
    <form @submit.prevent="submitForm">
      <div
        class="form-group last-connection"
        :class="{ 'older-than-one-month': isOlderThanOneMonth }"
      >
        <label for="last_connection">Dernière connexion :</label>
        <p>{{ formattedDateLastConnexion }}</p>
      </div>

      <div class="form-group">
        <label for="nom">Nom</label>
        <input
          type="text"
          id="nom"
          v-model="editableUser.lastname"
          placeholder="Votre nom complet"
          required
        />
      </div>

      <div class="form-group">
        <label for="prenom">Prénom</label>
        <input
          type="text"
          id="prenom"
          v-model="editableUser.firstname"
          placeholder="Votre prénom complet"
          required
        />
      </div>

      <div v-if="editableUser.type_pratique === 'licence'" class="div-user-licence">
      <div class="form-group">
        <label for="email">Email</label>
        <input
          type="email"
          id="email"
          v-model="editableUser.email"
          placeholder="Votre email"
          required
        />
      </div>
        <div class="form-group" v-if="formattedDate">
          <label for="createdAt">Date de création</label>
          <div class="date-display">{{ formattedDate }}</div>
        </div>

        <div class="form-group" v-if="formattedDateStartLicence">
          <label for="debutLicence">Date debut de licence</label>
          <div class="date-display">{{ formattedDateStartLicence }}</div>
        </div>

        <div class="form-group" v-if="editableUser.duree_acces">
          <label for="duree_acces">Durée d'accès</label>
          <select
            id="duree_acces"
            v-model="editableUser.duree_acces"
            required
            class="styled-select"
          >
            <option value="" disabled selected>Choisir la durée d'accès</option>
            <option value="1">1 mois</option>
            <option value="2">2 mois</option>
            <option value="3">3 mois</option>
            <option value="4">4 mois</option>
          </select>
        </div>
        <div class="form-group">
          <label>Date de fin de la licence :</label>
          <p>{{ dateFinLicence }}</p>
        </div>
      </div>
      <div class="form-group">
        <button type="submit">Mettre à jour</button>
      </div>
    </form>
  </div>
</template>

<script>
import { resetIdPatientGlobal } from "@/services/utilisateurs/globalSettingsUser.js";
import ParseConfig from "@/api/parse.config";
import Parse from "parse";

export default {
  name: "ModificationUserComponent",
  data() {
    return {
      user: null, // L'utilisateur d'origine
      editableUser: null, // Copie modifiable de l'utilisateur
      formattedDate: "", // Pour stocker la date formatée
      formattedDateLastConnexion: "", // Date de dernière connexion formatée
    };
  },
  methods: {
    submitForm() {
      ParseConfig();
      console.log("Form submitted with: ", this.editableUser);

      Parse.Cloud.run("modifyUserDetails", {
        userObjectId: this.user.id,
        nom: this.editableUser.lastname,
        prenom: this.editableUser.firstname,
        mail: this.editableUser.email,
        duree_acces: Number(this.editableUser.duree_acces),
        TherapeuteObjectId: Parse.User.current().id,
      })
        .then((response) => {
          console.log("Successfully updated user:", response);
          this.$emit("showToast", "Utilisateur modifié avec succès", true);
        })
        .catch((error) => {
          console.log("Failed to update user", error);
          this.$emit(
            "showToast",
            "Erreur lors de la modification de l'utilisateur",
            false
          );
        });
    },
    async findDataUser(id) {
      const user = await Parse.Cloud.run("getUser", { id: id });

      this.user = user;
      this.editableUser = { ...user.attributes }; // Cloner l'objet pour modification
      console.log("Utilisateur pour modification", this.user);

      // Formater la date de création
      this.formattedDate = new Date(
        user.attributes.createdAt
      ).toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      // Formater la date de dernière connexion
      this.formattedDateLastConnexion = new Date(
        user.attributes.derniere_connexion
      ).toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      // Formater la date de début de licence
      this.formattedDateStartLicence = new Date(
        user.attributes.date_debut_licence
      ).toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
  created() {
    console.log("ModificationUserComponent created");
    this.$emit("isLoadingActivitySpace", false);
    try {
      this.findDataUser(this.$IdPatient);
    } catch (error) {
      console.error("Erreur lors de la récupération de l'utilisateur:", error);
    } finally {
      resetIdPatientGlobal();
    }
  },
  computed: {
    // Calculer la date de fin de licence en fonction de la durée choisie
    dateFinLicence() {
      if (
        !this.editableUser.date_debut_licence ||
        !this.editableUser.duree_acces
      ) {
        return "";
      }
      const dateDebut = new Date(this.editableUser.date_debut_licence);
      const dateFin = new Date(
        dateDebut.setMonth(dateDebut.getMonth() + this.editableUser.duree_acces)
      );
      return dateFin.toLocaleDateString(); // Formatage de la date
    },

    // Vérifier si la dernière connexion est plus ancienne d'un mois
    isOlderThanOneMonth() {
      if (!this.editableUser.derniere_connexion) {
        return false;
      }
      const lastConnectionDate = new Date(this.editableUser.derniere_connexion);
      const currentDate = new Date();
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(currentDate.getMonth() - 1);

      return lastConnectionDate < oneMonthAgo;
    },
  },
};
</script>

<style scoped>
.last-connection {
  padding: 15px;
  background-color: #e0f7fa; /* Fond bleu clair */
  border: 1px solid #0097a7; /* Bordure bleu */
  border-radius: 5px;
  font-size: 14px;
  color: #004d40;
}

.older-than-one-month {
  background-color: #ffebee; /* Fond rouge clair */
  border: 1px solid #f44336; /* Bordure rouge */
  color: #d32f2f; /* Texte rouge */
}

.form-container {
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
  padding: 20px;
  border-radius: 10px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}
.date-display {
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
}
.styled-select {
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  width: 100%; /* Si tu veux qu'il occupe toute la largeur */
  box-sizing: border-box; /* S'assure que le padding ne dépasse pas */
}

.form-group label {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
  display: block;
}
input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #239380;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #1d6e61;
}
</style>
