<template>
  <div>
    <div class="columns is-multiline">
      <div
        class="column is-12-mobile is-12-tablet is-6-desktop is-4-widescreen"
      >
        <p class="has-text-weight-semibold is-size-5">
          {{ $t("listeUtilisateurInvite") }}
        </p>
      </div>
      <div
        class="column is-12-mobile is-12-tablet is-2-desktop is-3-widescreen"
      >
        <b-tooltip
          label="L'utilisateur 'en séance' pratique ses exercices dans l'établissement du professionnel et en sa présence."
          position="is-bottom"
          size="is-large"
          multilined
        >
          <b-icon pack="fas" icon="circle" class="color-enseance"></b-icon>
          <!-- <b-button label="Multiline (large)" type="is-top" /> -->
          {{ $t("pratiqueAuCabinet") }}
        </b-tooltip>
      </div>
      <div
        class="column is-12-mobile is-12-tablet is-2-desktop is-3-widescreen"
      >
        <b-tooltip
          label="L'utilisateur peut pratiquer ses exercices 'à distance', au-delà de votre établissement."
          position="is-bottom"
          size="is-large"
          multilined
        >
          <b-icon pack="fas" icon="circle" class="color-adistance"></b-icon>
          {{ $t("pratiqueSousLicence") }}
        </b-tooltip>
      </div>
      <div
        class="column is-12-mobile is-12-tablet is-2-desktop is-2-widescreen"
      >
        <b-tooltip
          label="Un utilisateur 'à distance', dont l'accès est expiré, peut être re-basculer en statut utilisateur 'en séance'."
          position="is-bottom"
          size="is-large"
          multilined
        >
          <b-icon pack="fas" icon="circle" type="is-warning"></b-icon>
          {{ $t("licenceExpiree") }}
        </b-tooltip>
      </div>
    </div>
    <div v-if="listeUser.length" class="tableSuivi">
      <div class="table-container">
        <table class="custom-table">
          <thead>
            <tr>
              <th>Nom / Prénom / Mail</th>
              <!-- <th>Mail</th> -->
              <th>Date de création</th>
              <th>Dernière connexion</th>
              <th>Durée d'accès</th>
              <th>Statut</th>
              <th>Modifier</th>
              <th>Suivi</th>
               <!-- <th>QR CODE</th>  -->
              <th>Accès</th>
            </tr>
          </thead>
          <template v-for="user in listeUser">
            <tr
              :class="{ 'selected-user': selectedUser === user }"
              :key="user.id"
            >
              <td
                :class="{
                  'bfp-done': user.bfp_result,
                  'bfp-not-done': !user.bfp_result,
                  'bfp-critical': user.bfp_result,
                }"
              >
                <div class="user-info">
                  <strong>Nom: </strong>{{ user.nom }}
                </div>
                <div class="user-info">
                  <strong>Prénom: </strong>{{ user.prenom }}
                </div>
                <div class="user-info">
                  <strong>Mail: </strong
                  >{{ checkEmailFormat(user.mail) ? " " + user.mail : "" }}
                </div>
                <div v-if="user.bfp_result" class="notification-circle"></div>
              </td>

              <td class="has-text-centered tdVerticalAlign">
                <div class="user-creation-date">
                  {{ new Date(user.createdAt).toLocaleDateString() }}
                </div>
              </td>
              <!-- Dernière connexion -->
              <td class="has-text-centered tdVerticalAlign">
                <div v-if="user.derniere_connexion" class="user-creation-date">
                  {{ new Date(user.derniere_connexion).toLocaleDateString() }}
                </div>
                <div v-else class="user-creation-date-attente">
                  <i class="fas fa-clock"></i> En attente de connexion
                </div>
                <!-- <div
                  v-else-if="
                    user !== undefined &&
                    user.typePratique === 'licence' &&
                    !user.derniere_connexion
                  "
                  @click="openRelance(user)"
                >
                  <a style="color: black">
                    <h1 class="title is-size-5">
                      <b-tooltip
                        size="is-small"
                        multilined
                        type="is-primary"
                        :label="$t('explicationEnAttente')"
                        position="is-right"
                      >
                        <img
                          class="icon-tableau"
                          src="../../../../assets/img/timer.png"
                        />
                      </b-tooltip>
                    </h1>
                  </a>
                </div>

                <div v-else>
                  <a style="color: black">
                    <h1 class="title is-size-5">
                      <b-tooltip
                        size="is-small"
                        multilined
                        type="is-primary"
                        :label="$t('explicationEnAttente2')"
                        position="is-right"
                      >
                        <img
                          class="icon-tableau"
                          src="../../../../assets/img/timer.png"
                        />
                      </b-tooltip>
                    </h1>
                  </a>
                </div> -->
              </td>
              <td class="has-text-centered tdVerticalAlign">
                <span
                  v-if="
                    user !== undefined &&
                    user.dureeAcces &&
                    user.typePratique === 'licence'
                  "
                  class="user-access-duration"
                >
                  {{
                    user === undefined
                      ? ""
                      : user.dureeAcces == 1000
                      ? "Illimité"
                      : user.dureeAcces + " mois"
                  }}
                </span>
              </td>
              <td class="has-text-centered tdVerticalAlign">
                <div
                  class="user-distance"
                  v-if="
                    user !== undefined &&
                    user.typePratique === textLicence &&
                    user.accesActif
                  "
                >
                  <i class="fas fa-circle color-adistance"></i
                  ><span> À distance</span>
                </div>
                <div
                  class="user-nonactif"
                  v-else-if="
                    user !== undefined &&
                    user.typePratique === textLicence &&
                    !user.accesActif
                  "
                >
                  <i class="fas fa-circle text-warning"></i
                  ><span> Non actif</span>
                </div>
                <div class="user-seance" v-else>
                  <i class="fas fa-circle color-enseance"></i
                  ><span> En séance</span>
                </div>
              </td>

              <!-- Pour les autres colonnes, ajoutez des directives similaires à celles ci-dessus -->
              <!-- Pour les colonnes bouton, vous pouvez utiliser des éléments <button> ou <a> comme vous le souhaitez -->
              <td class="has-text-centered table-button tdVerticalAlign">
                <div v-if="user !== undefined && user.seance_group">
                  <b-tooltip
                    label="L'utilisateur est associé à une séance de groupe, pour le modifier vous devez le dissocier de la séance de groupe"
                    position="is-left"
                  >
                    <img
                      class="icon-tableau"
                      src="../../../../assets/img/block.png"
                    />
                  </b-tooltip>
                </div>
                <a v-else @click="openCustomizeUser(user)">
                  <!-- <i
                    class="fas fa-user-edit is-margin-l-10 is-margin-r-10 has-text-black"
                  ></i> -->
                  <button class="buttonModifUser">
                    Modifier
                    <i
                      class="fas fa-user-edit is-margin-l-10 is-margin-r-10 has-text-white"
                    ></i>
                  </button>
                </a>
              </td>
              <!-- <td class="has-text-centered table-button">
                <a @click="openCustomizeUser(user)">
                  <i
                    class="fas fa-cog is-margin-l-10 is-margin-r-10 has-text-black"
                  ></i>
                </a>
              </td>  -->

              <td class="has-text-centered table-button tdVerticalAlign">
                <a @click="openSuivi(user)">
                  <!-- <div class="loader-container-relecture" v-if="isLoading && selectedUser===user">
                    <div class="loader-relecture"></div>
                  </div> -->
                  <button
                    v-if="
                      user !== undefined &&
                      user.typePratique !== 'cabinet' &&
                      (user.acces_donnees_therapeute === undefined ||
                        !user.acces_donnees_therapeute)
                    "
                    class="buttonModifUserDisabled"
                  >
                    Suivi non disponible
                    <i class="fas fa-ban"></i>
                  </button>
                  <button v-else class="buttonModifUser">
                    Suivi des exercices
                    <!-- <img
                      src="../../../../assets/img/graphic-progression-white.png"
                      class="img-progression"
                    /> -->
                    <i
                      class="fas fa-chart-line is-margin-l-10 is-margin-r-10 has-text-white"
                    ></i>
                  </button>
                  <!-- <img
                    class="icon-tableau"
                    v-else
                    src="../../../../assets/img/graphic-progression.png"
                    @load="onImageLoad"
                  /> -->
                </a>
              </td>
                <!-- <td class="has-text-centered table-button tdVerticalAlign">
                <a @click="openQrCode(user)">
                  <button
                    class="buttonModifUser"
                    :disabled="user.typePratique === 'cabinet'"
                    :class="{
                      'button-disabled': user.typePratique === 'cabinet',
                    }"
                  >
                    QR CODE
                    <i class="fas fa-qrcode icon"></i>
                  </button>
                </a>
              </td>   -->
              <td class="has-text-centered tdVerticalAlign">
                <!-- <div v-if="user !== undefined"> -->
                <!-- <img
                      src="../../../../assets/img/acces_front_cabinet_001_160519.png"
                      class="img-acces-front"
                    /> -->
                <button
                  @click="
                    isCardRedirectFront = true;
                    userSelected = user;
                  "
                  class="buttonModifUser"
                >
                  Espace activités patient
                  <!-- <img
                    src="../../../../assets/img/acces_front.png"
                    class="img-acces-front"
                  /> -->
                  <i class="fas fa-arrow-right"></i>
                </button>
                <!-- </div> -->
              </td>
            </tr>

            <!-- La ligne qui s'ouvre lorsqu'un utilisateur est sélectionné pour le suivi -->
            <tr
              ref="divBfp"
              v-if="
                selectedUser === user && selectedUserForCustomization !== user
              "
              :key="user.id + 'suivi'"
            >
              <td colspan="11" style="padding: 25px">
                <div
                  v-if="!displaySuiviData"
                  class="loader-detail-relecture"
                ></div>

                <relectureUser
                  :key="keyRelecture"
                  @closeModal="selectedUser = null"
                  v-else
                  :filesDones="suiviData"
                  :selectedUser="selectedUser"
                ></relectureUser>
              </td>
              <!-- <td colspan="10"></td> -->
            </tr>
            <tr
              v-if="
                selectedUserForCustomization === user && selectedUser !== user
              "
              :key="user.id + 'custom'"
            >
              <td colspan="11" style="padding: 25px">
                <!-- Votre contenu personnalisé ici -->
                <div>
                  <PersonnalisationAcces
                    @refreshListUser="handleGetMyUsers"
                    :selectedUser="selectedUserForCustomization"
                  />
                </div>
              </td>
            </tr>
          </template>
        </table>
      </div>

      <!-- <table
        class="table responsive-table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
      > -->
    </div>
   
    <div v-else>
      {{ $t("aucunUtilisateur") }}
    </div>
    <!-- <div v-else>
      <div class="loader-list-user"></div>
    </div> -->
    <!-- {{loadingUserList}} -->
    <b-modal
      :width="1200"
      :active.sync="isCardRedirectFront"
      @close="
        isCardRedirectFront = false;
        userSelected = null;
      "
      style="background-color: transparent !important"
    >
      <div class="card" v-if="userSelected">
        <div class="card-content">
          <div class="content">
            <h3>
              {{ $t("redirectionProVersCabinet") }} {{ userSelected.mail }}
            </h3>
            <p>
              {{ $t("fermetureSessionPro") }}
            </p>
            <div>
              <div class="divImageRedirection">
                <img
                  src="https://www.symbiocenter.com/assets/img/icone_capteur_003_140918.png"
                />
              </div>
              <div>
                <div class="texteRouge">{{ $t("sessionCabinetLigne1") }}</div>
                <div>{{ $t("sessionCabinetLigne2") }}</div>
                <div>{{ $t("sessionCabinetLigne3") }}</div>
                <div>{{ $t("sessionCabinetLigne4") }}</div>
              </div>
            </div>
            <a
              class="is-pulled-right button is-cyan"
              @click="redirectFront(userSelected)"
            >
              {{ $t("confirmer") }}
            </a>
            <a
              class="is-pulled-right button is-grey is-margin-r-10"
              @click="isCardRedirectFront = false"
            >
              {{ $t("annuler") }}
            </a>
            <br />
          </div>
        </div>
      </div>
    </b-modal>
    <qrCodePopUp
      v-if="PopUpQrCode"
      @closeModal="PopUpQrCode = false"
      :tokenGenerate="tokenGenerate"
    />
  </div>
</template>

<script>
import moment from "moment";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import { createNamespacedHelpers } from "vuex";
import { TYPE_PRATIQUE } from "@/configuration/userGroupConf.js";
import { URL_FRONT } from "@/configuration/urlConf.js";
import * as types from "@/store/user-mutation-type";
import checkEmailFormat from "@/services/general/checkEmailFormat";
import { getSuiviForUser } from "@/services/utilisateurs/getSuiviForUser.js";
import relectureUser from "../relecture/relectureUser.vue";
import PersonnalisationAcces from "../utilisateur/PersonnalisationAcces";
import qrCodePopUp from "../qrCode/qrCodePopUp.vue";
import AuthService from "@/services/utilisateurs/authService.js"; // Assurez-vous du bon chemin d'import

import {setIdPatientGlobal} from "@/services/utilisateurs/globalSettingsUser.js";

const User = createNamespacedHelpers("user");
export default {
  name: "liste-utilisateurs",
  props: ["usersInMyGroup", "group"],
  components: {
    relectureUser,
    PersonnalisationAcces,
    qrCodePopUp,
  },
  data() {
    return {
      textCabinet: TYPE_PRATIQUE.cabinet,
      textLicence: TYPE_PRATIQUE.licence,
      textAffiliation: TYPE_PRATIQUE.affiliation,
      userSelected: null,
      isCardRedirectFront: false,
      listeUser: [],
      selectedUser: null,
      suiviData: null,
      displaySuiviData: false,
      selectedUserForCustomization: null,
      isLoading: false,
      keyRelecture: 0,
      IdUserBfp: null,
      PopUpQrCode: false,
      loadingUserList: false,
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   // Remplacez `fetchData` par la fonction qui charge vos données
  //   fetchData().then((data) => {
  //     next((vm) => vm.setData(data));
  //   });
  // },
  // beforeRouteUpdate(to, from, next) {
  //   // Remplacez `fetchData` par la fonction qui charge vos données
  //   fetchData().then((data) => {
  //     this.setData(data);
  //     next();
  //   });
  // },
  computed: {
    ...User.mapGetters(["user"]),
  },
  methods: {
    ...User.mapActions({
      actionsUpdateUser: "updateUser",
    }),
    async openQrCode(user) {
      console.log("USER", user);
      this.tokenGenerate = await AuthService.generateToken(user.objectId);
      console.log("TOKEN GENERATE", this.tokenGenerate);
      this.PopUpQrCode = true;
    },
    onImageLoad() {
      this.isLoading = false;
    },
    ...User.mapMutations({
      editUser: types.EDIT_USER,
      editFirstConnection: types.EDIT_FIRST_CONNECTION,
    }),
    setData(data) {
      // Mettez à jour vos données ici
      this.listeUser = data;
    },
    checkEmailFormat(mail) {
      return checkEmailFormat(mail);
    },
    openEditUser(e) {
      this.$emit("openEditUser", e);
    },
    async openSuivi(e) {
      this.selectedUserForCustomization = null;
      this.isLoading = true;
      // Réinitialiser les valeurs
      
      this.selectedUser = e;

      this.displaySuiviData = false;
      this.suiviData = null;

      try {
        // Charger les données de suivi
        this.suiviData = await getSuiviForUser(e.objectId);
        this.displaySuiviData = true;
        this.isLoading = false;
        this.keyRelecture++;
      } catch (error) {
        // Gérer les erreurs de chargement des données
        console.error("Erreur lors du chargement des données de suivi:", error);
      }
    },
    openCustomizeUser(e) {
      if (
        this.selectedUserForCustomization &&
        this.selectedUserForCustomization === e
      ) {
        // Si l'utilisateur sélectionné est déjà ouvert, le fermer
        this.selectedUserForCustomization = null;
      } else {
        // Sinon, ouvrir le nouvel utilisateur sélectionné
        this.selectedUser = null;
        console.log("selectedUserForCustomization", e);
        this.selectedUserForCustomization = e;
      }
    },
    resetOpenCustomizeUser() {
      this.selectedUserForCustomization = null;
    },
    openRelance(e) {
      this.$emit("openRelance", e);
    },
    handleGetMyUsers() {
      this.$emit("refreshListUser");
    },
    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length == 2) return parts.pop().split(";").shift();
    },
    async UpdateConnexion(IdUser) {
      try {
        Parse.Cloud.run("updateLastConnection", {
          userObjectId: IdUser,
        })
          .then((response) => {
            console.log("Success:", response.message);
            console.log("Dernière connexion:", response.derniere_connexion);
          })
          .catch((error) => {
            console.error("Error:", error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },
    test(user) {
      console.log(user);
    },
    async redirectFront(e) {
      this.UpdateConnexion(e.objectId);
      await this.$emit("closeModal");
      localStorage.objectIdToUse = e.objectId;

      if (e.exos_personnaliser || e.typePratique === "licence") {
        // Avec accès personnalisé aux exercices
        // localStorage.setItem("idPatientPerso", e.objectId);
        setIdPatientGlobal(e.objectId);
        window.location.href = `${URL_FRONT}?objectId=${localStorage.objectIdToUse}`;
        // détruire le cookie :
        document.cookie =
          "idUserForSave=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      } // Sans accès personnalisé aux exercices ( Hérite des accès du thérapeute )
      else {
        localStorage.setItem("idUserForSave", e.objectId);
        //console.log('Création du cookie :', e.objectId)
        setIdPatientGlobal(e.objectId);

        document.cookie =
          "idUserForSave=" + e.objectId + ";path=/;max-age=3600"; // expire après 1 heure
        window.location.href = URL_FRONT;
      }
    },
    updateUserAccess() {
      Parse.Cloud.run("updateUserAccess", {
        usersInMyGroup: this.usersInMyGroup,
      })
        .then((response) => {
          console.log(response); // "Mise à jour terminée avec succès."
        })
        .catch((error) => {
          console.error("Erreur:", error);
        });
    },
    getInfoBfp() {
      Parse.Cloud.run("getInfoBfpUser", {
        usersInMyGroup: this.usersInMyGroup,
      })
        .then((response) => {
          console.log(response);
          this.listeUser = response.sort(function (a, b) {
            return b.createdAt.getTime() - a.createdAt.getTime();
          });
        })
        .catch((error) => {
          console.error("Erreur:", error);
        });
    },
    scrollToTarget() {
      // Récupérez la référence vers l'élément cible en utilisant "this.$refs"
      const targetElement = this.$refs.divBfp;

      // Vérifiez si l'élément cible existe
      if (targetElement) {
        // Faites défiler jusqu'à l'élément cible en utilisant scrollIntoView()
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
  },
  created() {},
  async mounted() {
    var dt = new Date();
    dt.setDate(dt.getDate());
    console.log(this.usersInMyGroup);
    this.loadingUserList = true;
    this.updateUserAccess();
    this.getInfoBfp();
    console.log('loadingUserList', this.loadingUserList);
    this.loadingUserList = false;

    try {
      this.IdUserBfp = this.getCookie("IdUserBfp");
      // console.log("IdUserBfp", this.IdUserBfp);
      console.log("this.listeUser", this.usersInMyGroup);
      var UserBFP = this.usersInMyGroup.find(
        (user) => user.objectId === this.IdUserBfp
      );
      if (UserBFP) {
        this.openSuivi(UserBFP);
        setTimeout(() => {
          this.scrollToTarget();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style>
.icon-tableau {
  max-width: 25px;
}

.tableSuivi {
  margin-right: 30px;
}
.selected-user {
  /* background-color: #5a8a6c; */
}
.loader-detail-relecture {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #20948b;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: auto;
  margin-top: 20px;
}
.img-acces-front {
  margin-left: 8px;
}
.img-progression {
  margin-left: 8px;
  width: 25px;
  height: 25px;
}
.loader-container-relecture {
  position: relative;
  display: inline-block;
}
.tdVerticalAlign {
  text-align: center !important;
  vertical-align: middle !important;
}
.buttonAccesCompte {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #239380 !important;
  padding: 5px !important;
  color: white;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}
.buttonAccesCompte:hover {
  transform: scale(1.04);
  border: 1px solid white;
}

.buttonAccesCompteDisabled {
  background-color: #858484 !important;
  opacity: 0.5;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px !important;
  color: white;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}
.buttonAccesCompteDisabled:hover {
  transform: scale(1.04);
  border: 1px solid white;
}

.loader-relecture {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.user-last-connection {
  font-size: 14px; /* Légèrement plus petit pour une meilleure lisibilité */
  font-weight: normal; /* Normal (non gras) pour un aspect plus subtil */
  color: #555; /* Couleur de texte légèrement plus claire */
  background-color: #f5f5f5; /* Une teinte légèrement plus claire pour le fond */
  padding: 5px 8px; /* Espacement intérieur légèrement réduit */
  border: 1px solid #ccc; /* Bordure légère pour distinguer l'élément */
  border-radius: 4px; /* Coins légèrement plus arrondis */
  display: inline-block;
  margin-right: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Légère ombre pour la profondeur */
}
.user-creation-date {
  font-size: 14px; /* Légèrement plus petit pour une meilleure lisibilité */
  font-weight: normal; /* Normal (non gras) pour un aspect plus subtil */
  color: #555; /* Couleur de texte légèrement plus claire */
  background-color: #f5f5f5; /* Une teinte légèrement plus claire pour le fond */
  padding: 5px 8px; /* Espacement intérieur légèrement réduit */
  border: 1px solid #ccc; /* Bordure légère pour distinguer l'élément */
  border-radius: 4px; /* Coins légèrement plus arrondis */
  display: inline-block;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Légère ombre pour la profondeur */
}
.user-creation-date-attente {
  font-size: 12px; /* Légèrement plus petit pour une meilleure lisibilité */
  font-weight: normal; /* Normal (non gras) pour un aspect plus subtil */
  color: #555; /* Couleur de texte légèrement plus claire */
  background-color: #f5f5f5; /* Une teinte légèrement plus claire pour le fond */
  padding: 5px 8px; /* Espacement intérieur légèrement réduit */
  border: 1px solid #ccc; /* Bordure légère pour distinguer l'élément */
  border-radius: 4px; /* Coins légèrement plus arrondis */
  display: inline-block;
  /* margin-right: 10px; */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Légère ombre pour la profondeur */
}
.user-creation-date-attente:hover {
  background-color: #228676; /* Couleur de fond légèrement plus foncée au survol */
  transition: background-color 0.2s, color 0.2s; /* Transition de couleur au survol */
  transform: scale(1.04);
  cursor: pointer;
  color: white;
}
.user-creation-date:hover {
  background-color: #228676; /* Couleur de fond légèrement plus foncée au survol */
  transition: background-color 0.2s, color 0.2s; /* Transition de couleur au survol */
  transform: scale(1.04);
  cursor: pointer;
  color: white;
}
.user-access-duration {
  font-size: 13px; /* Taille de police */
  font-weight: bold; /* Gras */
  color: #239380; /* Couleur de texte bleue (vous pouvez ajuster la couleur selon vos préférences) */
  background-color: #f0f0f0; /* Couleur de fond grise */
  padding: 5px 10px; /* Espacement intérieur */
  border-radius: 5px; /* Coins arrondis */
  display: inline-block; /* Afficher en ligne */
}
.user-distance {
  font-size: 13px; /* Taille de police */
  font-weight: bold; /* Gras */
  color: #b83d99; /* Couleur de texte bleue (vous pouvez ajuster la couleur selon vos préférences) */
  background-color: #f0f0f0; /* Couleur de fond grise */
  padding: 5px 10px; /* Espacement intérieur */
  border-radius: 5px; /* Coins arrondis */
  display: inline-block; /* Afficher en ligne */
}
.user-seance {
  font-size: 13px; /* Taille de police */
  font-weight: bold; /* Gras */
  color: #4da0ff; /* Couleur de texte bleue (vous pouvez ajuster la couleur selon vos préférences) */
  background-color: #f0f0f0; /* Couleur de fond grise */
  padding: 5px 10px; /* Espacement intérieur */
  border-radius: 5px; /* Coins arrondis */
  display: inline-block; /* Afficher en ligne */
}
.user-nonactif {
  font-size: 13px; /* Taille de police */
  font-weight: bold; /* Gras */
  color: black; /* Couleur de texte bleue (vous pouvez ajuster la couleur selon vos préférences) */
  background-color: #f0f0f0; /* Couleur de fond grise */
  padding: 5px 10px; /* Espacement intérieur */
  border-radius: 5px; /* Coins arrondis */
  display: inline-block; /* Afficher en ligne */
}

@media screen and (max-width: 1800px) {
  .user-access-duration {
    font-size: 11px; /* Taille de police */
    font-weight: bold; /* Gras */
    color: #239380; /* Couleur de texte bleue (vous pouvez ajuster la couleur selon vos préférences) */
    background-color: #f0f0f0; /* Couleur de fond grise */
    padding: 2px 4px; /* Espacement intérieur */
    border-radius: 5px; /* Coins arrondis */
    display: inline-block; /* Afficher en ligne */
  }
  .user-distance {
    font-size: 11px; /* Taille de police */
    font-weight: bold; /* Gras */
    color: #b83d99; /* Couleur de texte bleue (vous pouvez ajuster la couleur selon vos préférences) */
    background-color: #f0f0f0; /* Couleur de fond grise */
    padding: 2px 4px; /* Espacement intérieur */
    border-radius: 5px; /* Coins arrondis */
    display: inline-block; /* Afficher en ligne */
  }
  .user-seance {
    font-size: 11px; /* Taille de police */
    font-weight: bold; /* Gras */
    color: #4da0ff; /* Couleur de texte bleue (vous pouvez ajuster la couleur selon vos préférences) */
    background-color: #f0f0f0; /* Couleur de fond grise */
    padding: 2px 4px; /* Espacement intérieur */
    border-radius: 5px; /* Coins arrondis */
    display: inline-block; /* Afficher en ligne */
  }
}

.buttonModifUser {
  background-color: #239380; /* Couleur de fond bleue (vous pouvez ajuster la couleur) */
  color: #fff; /* Couleur de texte blanche */
  font-size: 12px; /* Taille de police encore plus réduite */
  font-weight: bold; /* Gras */
  border: none; /* Supprimer la bordure */
  border-radius: 5px; /* Coins arrondis */
  padding: 6px 12px; /* Espacement intérieur réduit */
  cursor: pointer; /* Curseur de type main pour indiquer que c'est cliquable */
  transition: background-color 0.2s, color 0.2s; /* Transition de couleur au survol */
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.buttonModifUser i {
  font-size: 14px; /* Taille de police de l'icône encore plus réduite */
  margin-left: 6px; /* Espacement à gauche de l'icône encore plus réduit */
}

.buttonModifUser:hover {
  background-color: #228676; /* Couleur de fond légèrement plus foncée au survol */
  transition: background-color 0.2s, color 0.2s; /* Transition de couleur au survol */
  transform: scale(1.04);
  cursor: pointer;
  color: white;
}
.buttonModifUserDisabled {
  background-color: #858484 !important;
  color: black; /* Couleur de texte blanche */
  opacity: 0.5;
  font-size: 12px; /* Taille de police encore plus réduite */
  font-weight: bold; /* Gras */
  border: none; /* Supprimer la bordure */
  border-radius: 5px; /* Coins arrondis */
  padding: 6px 12px; /* Espacement intérieur réduit */
  cursor: pointer; /* Curseur de type main pour indiquer que c'est cliquable */
  transition: background-color 0.2s, color 0.2s; /* Transition de couleur au survol */
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.buttonModifUserDisabled i {
  font-size: 14px; /* Taille de police de l'icône encore plus réduite */
  margin-left: 6px; /* Espacement à gauche de l'icône encore plus réduit */
}

.buttonModifUserDisabled:hover {
  background-color: #228676 !important; /* Couleur de fond légèrement plus foncée au survol */
  transform: scale(1.04) !important;
  cursor: pointer !important;
  color: white !important;
  opacity: 1 !important;
}

.buttonModifUserDisabled::after {
  /* Styles du pseudo-élément */
  content: "Demander le suivi"; /* Texte au survol */
  display: none; /* Caché par défaut */
  position: absolute; /* Pour le positionner correctement */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #228676; /* Couleur de fond au survol */
  color: white; /* Couleur du texte au survol */
  text-align: center;
  border-radius: 5px; /* Coins arrondis */
  line-height: 25px;
}

.buttonModifUserDisabled:hover::after {
  /* Styles du pseudo-élément au survol */
  display: block; /* Affiche le texte au survol */
}

.responsive-table {
  overflow-x: auto;
}

@media screen and (max-width: 600px) {
  .responsive-table td:nth-of-type(1):before {
    content: "Nom";
  }
  .responsive-table td:nth-of-type(2):before {
    content: "Prénom";
  }
  .responsive-table td:nth-of-type(3):before {
    content: "Mail";
  }

  .responsive-table td:nth-of-type(1),
  .responsive-table td:nth-of-type(2),
  .responsive-table td:nth-of-type(3) {
    /* Changez la taille de la police comme vous le souhaitez */
    font-size: 8px;
  }
}
.table-container {
  margin: 20px;
  overflow-x: auto;
}

.custom-table {
  border-collapse: collapse;
  text-align: center;
}

@media screen and (min-width: 1280px) {
  .custom-table {
    width: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .custom-table {
    width: 80%;
  }
}

.custom-table th,
.custom-table td {
  padding: 12px;
  border: 1px solid #b1b1b1;
}

.custom-table th {
  background-color: #239380;
  color: white;
  font-size: 16px;
  vertical-align: middle;
  padding-left: 20px;
}

.custom-table td {
  background: white;
  vertical-align: middle;
  font-size: 0.9rem;
}

@media only screen and (max-width: 600px) {
  .custom-table th,
  .custom-table td {
    font-size: 14px;
    padding: 8px;
  }

  .custom-table th {
    padding-left: 10px;
  }

  .custom-table thead {
    display: none; /* Hide the header on small screens */
  }

  .custom-table,
  .custom-table tbody,
  .custom-table tr,
  .custom-table td {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .custom-table td {
    text-align: left;
    padding-left: 15px;
    margin-bottom: 8px;
  }

  /* Add some visual separation between rows */
  .custom-table tr {
    border-bottom: 1px solid #b1b1b1;
  }
}

@media screen and (max-width: 1200px) {
  /* Appliquer le style seulement lorsque la largeur de l'écran est inférieure à 1024 pixels */
  .custom-table th:nth-child(2),
  .custom-table td:nth-child(2) {
    display: none; /* Masquer la colonne "Date de création" */
  }
}

.buttonModifUser.button-disabled {
  background-color: #ccc; /* Couleur de fond pour le bouton désactivé */
  color: #666; /* Couleur du texte du bouton désactivé */
  cursor: not-allowed; /* Change le curseur pour indiquer que le bouton est désactivé */
  opacity: 0.6; /* Rend le bouton légèrement transparent */
  border: 1px solid #aaa; /* Couleur de la bordure pour un meilleur contraste */
}
.bfp-done {
  background-color: #fffbe6; /* Fond doux pour attirer l'attention légèrement */
  border-left: 5px solid #ffc107; /* Bordure pour une alerte douce */
  padding: 10px;
  position: relative;
  transition: background-color 0.3s ease-in-out;
}

.bfp-done:hover {
  background-color: #ffecb3; /* Légère surbrillance au survol */
}

/* Cercle de notification pour indiquer l'exercice effectué */
.notification-circle {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 15px;
  height: 15px;
  background-color: red; /* Rouge vif pour attirer l'attention */
  border-radius: 50%; /* Forme de cercle */
  box-shadow: 0 0 10px rgba(255, 87, 34, 0.5); /* Légère ombre pour l'effet de notification */
  animation: pulse 1.5s infinite; /* Animation pour attirer l'attention */
}

/* Animation de pulsation pour le cercle */
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 87, 34, 0.5);
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 20px rgba(255, 87, 34, 0.7);
  }
}

/* Style pour les utilisateurs n'ayant pas fait l'exercice (bfp_result = false) */
.bfp-not-done {
  background-color: #f0f0f0; /* Fond neutre */
  border-left: 5px solid #ccc; /* Bordure discrète */
  padding: 10px;
  position: relative;
}

.user-info {
  margin-bottom: 5px;
}

.loader-list-user
{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loader-list-user::before
{
  content: "";
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #239380;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.tooltip-notif-circle {
  display: none; /* Caché par défaut */
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  top: -5px; /* Ajuste la position selon les besoins */
  left: 100%;
  white-space: nowrap;
  z-index: 10;
}

/* Affiche le message au survol */
.critical-score:hover .tooltip-notif-circle {
  display: block;
}

.bfp-done
{
  position: relative;
}


.bfp-critical:hover::after {
  content: "Score critique atteint !"; /* Message à afficher */
  position: absolute;
  top: 5%; /* Positionne en dessous de l'élément */
  right: -20%; /* Positionne à droite de l'élément */
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
  font-size: 12px;
  margin-top: 5px; /* Espace entre l'élément et le tooltip */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; /* Désactive les interactions avec le tooltip */
}

.bfp-critical:hover::after {
  opacity: 1; /* Affiche le tooltip au survol */
}
</style>
