<template>
  <div class="search-container">
    <input
      type="text"
      placeholder="Rechercher un utilisateur"
      v-model="searchQuery"
      @input="onInput"
    />
    <font-awesome-icon icon="search" class="search-icon" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: ''
    };
  },
  methods: {
    onInput() {
      this.$emit('search', this.searchQuery);
    }
  }
};
</script>

<style scoped>
/* Container for the search bar */
.search-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.search-icon {
  position: absolute;
  right: 15px; /* Icon positioned to the right */
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #aaa;
}

/* Improved search input style */
input {
  width: 100%;
  padding: 10px 45px 10px 15px; /* Adjusted padding: more space on the right for the icon */
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 50px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color: #fff;
}

/* Change border and shadow on focus */
input:focus {
  outline: none;
  border: 1px solid #239380; /* Primary color for focus */
}

/* Placeholder style */
input::placeholder {
  color: #aaa;
  font-style: italic;
}
</style>
