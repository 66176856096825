<template>
  <div class="dashboard-container">
    <div class="dashboard-content">
      <div class="dashboard-section" @click="goToTracking" :class="{ active: blocSelected === 'suiviUtilisateur' }">
        <h2><i class="fas fa-chart-bar icon"></i> Suivi d'utilisateur</h2>
        <p>Affichez les exercices effectués par l'utilisateur et consultez ses statistiques.</p>

      </div>
      <div class="dashboard-section" @click="goToEdit" :class="{ active: editUser }">
        <h2><i class="fas fa-user-edit icon"></i>Modification d'utilisateur</h2>
        <p>Modifiez les informations personnelles de l'utilisateur.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getSuiviForUser } from "@/services/utilisateurs/getSuiviForUser.js";

export default {
  name: "UserDashboard",
  data() {
    return {
      suiviData: null,
    };
  },
  props: {
    IdUser: {
      type: String,
      required: true,
    },
    blocSelected: {
      type: String,
      required: true,
    },
    editUser: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async goToTracking() {
      // Logique de redirection vers la page de suivi
      this.$emit("isLoadingActivitySpace", true);
      this.suiviData = await getSuiviForUser(this.IdUser);
      console.log("Suivi Data",this.suiviData);
      this.$emit("suivi-data", this.suiviData);
    },
    goToEdit() {
      this.$emit("isLoadingActivitySpace", true);
      this.$emit("update-user");

    },
  },
};
</script>

<style scoped>
.dashboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-content {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  border-left: 1px solid #239380;
  border-right:1px solid #239380;
  border-bottom: 1px solid #239380;
  border-top: none;
}

.dashboard-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  padding-top:0px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  
}

.dashboard-section:hover {
  background-color: #239380;
}
.dashboard-section:hover h2, .dashboard-section:hover p {
  color: white;
}
.dashboard-section:hover .icon {
  color: white;
}

.icon {
  font-size: 25px;
  color: #239380;
  /* margin-bottom: 15px; */
  margin-right: 10px;
  margin-top:15px;
}

h2 {
  color: #333;
  margin-bottom: 10px;
  font-size: 22px;
}
@media (max-width: 1200px) {
    .dashboard-section {
        padding: 15px;
    }
    h2 {
        font-size: 18px;
    }
    p
    {
        font-size: 14px;
    }
    .icon {
        font-size: 20px;
    }
}


@media (max-width: 1024px) {
    .dashboard-section
    {
        padding:10px;
    }
    h2 {
        font-size: 16px;
    }
    p
    {
        font-size: 12px;
    }
    .icon {
        font-size:18px;
    }
}  

@media (max-width: 768px) {
    .dashboard-section
    {
        padding:5px;
    }
    h2 {
        font-size: 14px;
    }
    p
    {
        font-size: 10px;
    }
    .icon {
        font-size: 18px;
    }
}
p {
  color: #666;
  font-size: 16px;
}

.dashboard-section h2,
.dashboard-section p {
  color: #239380;
}

.dashboard-section.active {
  background-color: #239380;
}
.dashboard-section.active h2, .dashboard-section.active p {
  color: white;
}
.dashboard-section.active .icon {
  color: white;
}

</style>
