<template>
    <div class="divRelectureUser">
      <relectureUser
        :key="keyRelecture"
        @closeModal="selectedUser = null"
        :filesDones="SuiviDataUser"
        :selectedUser="selectedUser"
        v-if="selectedUser"
      ></relectureUser>
    </div>
  </template>
  
  <script>
  import relectureUser from "../relecture/relectureUser.vue";
  import { getUserById } from "@/services/utilisateurs/getUserById";
  
  export default {
    name: "SuiviUtilisateur",
    data() {
      return {
        keyRelecture: 0,
        selectedUser: null,
      };
    },
    props: {
      SuiviDataUser: {
        type: Object,
        required: true,
        default: null,
      },
      IdUser: {
        type: String,
        required: true,
      },
    },
    watch:
    {
      SuiviDataUser: {
        handler: function (newValue) {
          console.log("SuiviDataUser changed", newValue);
        },
        immediate: true,
      },
    },
    components: {
      relectureUser,
    },
    async created() {
      console.log("SuiviDataUser Component", this.SuiviDataUser);
      
      try {
        const user = await getUserById(this.IdUser); // Utilisation de this.IdUser
        console.log('User récupérée:', user);
        this.selectedUser = user;
        this.$emit("isLoadingActivitySpace", false);

      } catch (error) {
        console.error("Erreur lors de la récupération de l'utilisateur:", error);
      }
    },
  };
  </script>
  <style scoped>
    .divRelectureUser {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 25px;
        padding: 20px;
        margin-top:20px;
    }
    </style>