<template>
  <button class="user-btn" @click="handleButtonClickNew()">
    <b-icon icon="account" class="user-icon"></b-icon>
    <span class="text-to-hide" v-if="idUserForSave && namePatientProps">
      Terminer la session de  {{ namePatientProps }}
    </span>
    <span class="text-to-hide" v-else-if="$Therapeute && !$IdPatient">
      Accès à l'Espace de gestion
    </span>
    <span class="text-to-hide" v-else-if="$Therapeute && $IdPatient">
      Terminer la session de {{ namePatient }}
    </span>
    <span class="text-to-hide" v-else> Mon compte </span>
  </button>
</template>
<script>
import { getUserById } from "@/services/utilisateurs/getUserById";
import {resetIdPatientGlobal} from "@/services/utilisateurs/globalSettingsUser.js";

export default {
  name: "UserButton",
  data() {
    return {
      namePatient: "",
    };
  },
  props:
  {
    idUserForSave: {
      type: String,
      default: null,
    },
    namePatientProps:
    {
      type: String,
      default: null,
    }
  },
  methods: {
    handleButtonClickNew() {
      // Logique lorsque le bouton est cliqué
      this.$emit("handleButtonClick");
      if (this.$Therapeute && this.$IdPatient) {
        this.resetIdPatient();
      }
    },
    async getNameUser() {
      try {
        var user = await getUserById(this.$IdPatient);
        if (user.length === 0) {
          return;
        }
        console.log("User found", user);
        this.namePatient = user.nom;
      } catch (error) {
        console.log("Error in UserButton created", error);
      }
    },
    resetIdPatient() {
        console.log("Resetting IdPatient");
      resetIdPatientGlobal();
    },
  },
  watch: {
    $Therapeute: {
      handler: function (newValue) {
        console.log("Therapeute changed", newValue);
      },
      immediate: true,
    },
    $IdPatient: {
      handler: function (newValue) {
        console.log("IdPatient changed", newValue);
        if (newValue) {
          this.getNameUser();
        }
      },
      immediate: true,
    },
    $NomPatient: {
      handler: function (newValue) {
        console.log("NomPatient changed", newValue);
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.user-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: linear-gradient(135deg, #489181, #3b7a6d);
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  font-weight: 600;
  font-size: 16px;
  -webkit-box-shadow: 0 4px 15px rgba(72, 145, 129, 0.2);
  box-shadow: 0 4px 15px rgba(72, 145, 129, 0.2);
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin-right: 24px;
}
</style>
