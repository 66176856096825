<template>
  <div>
  <div class="user-menu" v-if="!editUser">
    <div class="menu-header">
      <h2 class="menu-title">Utilisateurs</h2>
      <button class="create-user-button" @click="createUser">
        <font-awesome-icon icon="user-plus" class="legend-icon" />
        Ajouter un utilisateur
        </button>
      <SearchBar @search="updateSearchQuery" />
    </div>
    <UserListVerticalMenu :users="users" :selectedUser="selectedUser" @select-user-menu="selectUserMenu" @select-user="selectUser" :searchQuery="searchQuery" @suivi="handleSuivi" @edit="handleEdit" />
    <LoaderComponent v-if="isLoading" />
    <div v-if="error" class="error-message">{{ error }}</div>
  </div>
  <div v-else>
    <div class="menu-header">
      <h2 class="menu-title">Modification</h2>
      </div>
    <ModificationUserComponent  @isLoadingActivitySpace="handleIsLoadingActivitySpace"  @showToast="showToast" />  
  </div>
  
</div>
</template>

<script>
import { ref, onMounted } from "vue";
import Parse from "parse";
import LoaderComponent from "./loaderComponent.vue";
import SearchBar from "./SearchBar.vue";
import {setIdPatientGlobal,resetIdPatientGlobal} from "@/services/utilisateurs/globalSettingsUser.js";
import UserListVerticalMenu from "./UserListVerticalMenu.vue";
import ModificationUserComponent from "./ModificationUserComponent.vue";
export default {
  components: {
    LoaderComponent,
    SearchBar,
    UserListVerticalMenu,
    ModificationUserComponent,
  },
  watch: {
    $IdPatient: {
      handler: function (newValue) {
        console.log("IdPatient changed", newValue);
        if (newValue === null) {
          this.selectUser('activitySpace');
        }
        
      },
      immediate: true,
    },
  },
  props: {
    editUser: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showToast(message,isSuccess) {
      if (isSuccess) {
        this.$emit("showToastSuccess", message);
      } else {
        this.$emit("showToastError", message);
      }
    },
    handleSuivi(action) {
      console.log("Suivi", action);
      this.$emit("suivi", action);
    },
    handleEdit(action) {
      setIdPatientGlobal(action.id);
      console.log("Edit", action);
      this.$emit("edit", action);
    },
    createUser() {
      this.$emit("createUser");
    },
  },
  setup(_, { emit }) {
    const users = ref([]);
    const selectedUser = ref("activitySpace");
    const isLoading = ref(false);
    const error = ref(null);
    const links = ref([]);
    const isLoadingActivitySpace = ref(false);
    const searchQuery = ref("");
    const updateSearchQuery = (query) => {
      searchQuery.value = query;
    };

      const selectUserMenu = async (user) => {
        selectedUser.value = user;
        return;
      };

    const selectUser = async (user) => {
      console.log("selectUser 2", user);
      emit("isLoadingActivitySpace", true);

      if (user === 'activitySpace') {
        console.log("%cEntrée dans la méthode ActivitySpace", "color: blue;");
        selectedUser.value = 'activitySpace';
        links.value = [];
        document.cookie = "idUserForSave=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        emit('ReloadHeader', true);
        emit("IdUser", "");
        emit("linkUser", links.value);
        emit("isLoadingActivitySpace", false);
        resetIdPatientGlobal();
        console.log(user);
        return;
      }
      try {
        const linksResponse = await Parse.Cloud.run("getUserLinks", {
          objectId: user.id,
        });
        links.value = linksResponse || [];
        emit("linkUser", links.value);
        emit("isLoadingActivitySpace", false);
        emit("IdUser", user.id);
        setIdPatientGlobal(user.id);
        emit("UndisplayHeader", true);
        console.log("Liens trouvés:", links.value);
      } catch (err) {
        error.value = "Erreur lors de la récupération des liens.";
        console.error("Erreur lors de la récupération des liens:", err.message);
      }
    };

    const handleIsLoadingActivitySpace = (value) => {
      console.log("handleIsLoadingActivitySpace", value);
      emit("isLoadingActivitySpace", value);
    };

    const fetchUsersByGroupId = async (groupId) => {
      isLoading.value = true;
      error.value = null;
      try {
        const response = await Parse.Cloud.run("getUsersByGroupId", { groupId });
        users.value = response || [];
        console.log("Utilisateurs trouvés:", users.value);
      } catch (err) {
        error.value = "Erreur lors de la récupération des utilisateurs.";
        console.error("Erreur lors de la récupération des utilisateurs:", err.message);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      
      if (selectedUser.value === 'activitySpace') {
        document.cookie = "idUserForSave=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      }
      const groupId = Parse.User.current()?.get("group")?.id;
      if (groupId) {
        fetchUsersByGroupId(groupId);
      } else {
        error.value = "Aucun groupe associé à cet utilisateur.";
      }
    });

    return {
      users,
      selectedUser,
      isLoading,
      error,
      selectUser,
      links,
      isLoadingActivitySpace,
      handleIsLoadingActivitySpace,
      searchQuery,
      updateSearchQuery,
      selectUserMenu,
    };
  },
};
</script>
<style scoped>
.user-menu {
  width: 100%;
  max-width: 280px;
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 0.3s ease;
}

.menu-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 15px;
  padding-left: 15px;
}

.menu-title {
  font-size: 1.4rem;
  color: #333;
  font-weight: 600;
  margin: 0 0 15px 0;
}


.activity-space {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #ff4d4f;
  font-size: 0.9rem;
  padding: 12px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 6px;
  margin: 15px;
}

/* Styles pour le loader */
:deep(.loader) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

/* Media queries pour la responsivité */
@media (max-width: 768px) {
  .user-menu {
    max-width: 100%;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }

  .menu-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .menu-title {
    margin: 0;
  }

  .user-item {
    padding: 10px 14px;
  }

  .user-text {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .menu-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-title {
    margin-bottom: 10px;
  }

  .user-item {
    padding: 8px 12px;
  }

  .user-icon {
    font-size: 1rem;
  }

  .user-text {
    font-size: 0.85rem;
  }
}
.user-text {
  display: inline-block;
  max-width: 200px; /* Ajuste cette valeur selon ton design */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.create-user-button {
  align-self: flex-start;
  background-color: #239380;
  color: white;
  font-size: 0.9rem;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.create-user-button:hover {
  background-color: #1d7567;
  transition: background-color 0.3s easet;
  transform: scale(1.05);
}

</style>