<template>
  <div class="user-action-menu" v-if="selectedUser">
    <div class="action-bubbles">
      <button
        v-for="action in actions"
        :key="action.name"
        class="action-bubble"
        @click="handleAction(action.name)"
        :aria-label="action.label"
      >
        <font-awesome-icon :icon="action.icon" />
        <span>{{ action.label }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserActionMenu",
  props: {
    selectedUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      actions: [
        { name: "follow", label: "Suivi", icon: ["fas", "chart-line"] },
        { name: "edit", label: "Modifier", icon: ["fas", "edit"] },
        { name: "activity", label: "Activités", icon: ["fas", "tasks"] },
      ],
    };
  },
  methods: {
    handleAction(actionName) {
      console.log(
        `Action ${actionName} pour l'utilisateur ${this.selectedUser.get(
          "firstname"
        )}`
      );
      this.$emit("action", actionName, this.selectedUser);
    },
  },
};
</script>

<style scoped>
.user-action-menu {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}

.action-bubbles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  max-width: 100%;
}

.action-bubble {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: clamp(3rem, 15vw, 4rem);
  height: clamp(3rem, 15vw, 4rem);
  border-radius: 50%;
  background-color: #239380;
  color: white;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  padding: 0.5rem;
}

.action-bubble:hover,
.action-bubble:focus {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: white;
  color: #239380;
  border: 1px solid #239380;
}

.action-bubble svg {
  font-size: clamp(0.8rem, 4vw, 1.2rem);
  margin-bottom: 0.2rem;
}

.action-bubble span {
  font-size: clamp(0.6rem, 3vw, 0.8rem);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

@media (max-width: 768px) {
  .action-bubbles {
    gap: 0.5rem;
  }
}

@media (max-width: 480px) {
  .action-bubble span {
    display: none;
  }

  .action-bubble {
    width: clamp(2.5rem, 12vw, 3.5rem);
    height: clamp(2.5rem, 12vw, 3.5rem);
  }
}
</style>
