<template>
  <div></div>
</template>

<script>
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "NotificationToast",
  setup() {
    const createToast = () => {
      return Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        customClass: {
          popup: "animate__animated animate__fadeInUp",
        },
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    };

    const showToast = (message, icon = "success") => {
      const Toast = createToast();
      Toast.fire({
        icon: icon,
        title: message,
      });
    };

    return {
      showToast,
    };
  },
});
</script>
<style scoped>
@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate__fadeInUp {
  animation: fadeInUp 0.5s ease-out; /* Durée et type de l'animation */
}
</style>
