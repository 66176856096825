import { render, staticRenderFns } from "./HeaderForPatient.vue?vue&type=template&id=4fdb3bec&scoped=true"
import script from "./HeaderForPatient.vue?vue&type=script&lang=js"
export * from "./HeaderForPatient.vue?vue&type=script&lang=js"
import style0 from "./HeaderForPatient.vue?vue&type=style&index=0&id=4fdb3bec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fdb3bec",
  null
  
)

export default component.exports