<template>
  <div>
    <div class="carousel-title-div">
      <div class="carousel-title">{{title}}</div>
    </div>
    <div v-if="filteredFolders.length" class="DivGlobalFolders">
      <div
        v-for="folder in filteredFolders"
        :key="folder.id"
        class="folder"
        @click="toggleSelection(folder)"    

      >
        <div :class="['folder-header', { 'folder-selected': isSelected(folder) }]"  :style="{ backgroundColor: folder.color ? folder.color : 'transparent' }" >
          <img
            :src="folder.resource._url"
            v-if="folder.resource && folder.resource._url  && !folder.color"
            alt="Image de la brique"
            class="folder-image"
          />
          <h3 class="folder-title">{{ folder.name }}</h3>
        </div>
        <div class="div-info-icon">
          <i
            class="info-icon fas fa-info-circle"
            @click.stop="openInfoModal(folder)"
          ></i>
        </div>
      </div>
    </div>
    <div v-else>
      <p>Aucun dossier de programme disponible.</p>
    </div>
    <popUp v-if="showInfoModal" @close="showInfoModal = false" :selectedFolder="selectedFolder" />
  </div>
</template>
<script>
import PopUp from "./popUp.vue";
export default {
  name: "ProgrammeComponent",
  props: {
    availableFoldersProgrammes: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    PopUp,
  },
  data() {
    return {
      selectedFolders: [],
      showInfoModal: false,
      selectedFolder: null,
    };
  },
  methods: {
    toggleSelection(folder) {
      const index = this.selectedFolders.findIndex((f) => f.id === folder.id);
      if (index > -1) {
        // Si le dossier est déjà sélectionné, le retirer
        this.selectedFolders.splice(index, 1);
      } else {
        // Sinon, l'ajouter aux dossiers sélectionnés
        this.selectedFolders.push(folder);
      }
    },
    isSelected(folder) {
      return this.selectedFolders.some((f) => f.id === folder.id);
    },
    openInfoModal(folder) {
      this.selectedFolder = folder;
      this.showInfoModal = true;
    },
    closeInfoModal() {
      this.showInfoModal = false;
      this.selectedFolder = null;
    },
  },
  computed: {
    filteredFolders() {
      const uniqueFolders = new Map();
      this.availableFoldersProgrammes.forEach((folder) => {
        if (!uniqueFolders.has(folder.name)) {
          uniqueFolders.set(folder.name, folder);
        }
      });
      return Array.from(uniqueFolders.values());
    },
  },
  created() {
    console.log("Available folders", this.availableFoldersProgrammes, this.title);
  },
};
</script>

<style scoped>
.folder {
  width: 350px;
  height: 240px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.folder:hover {
  border-color: #239380; /* Couleur de la bordure au survol */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée au survol */
  transform: scale(1.05); /* Zoom au survol */
}

.folder-header {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0.8; /* Opacité pour les briques non sélectionnées */
    filter: grayscale(100%); /* Niveau de gris pour les briques non sélectionnées */
    transition: opacity 0.3s ease, filter 0.3s ease; /* Transition pour le hover */
}

.folder-image {
  width: 100%;
  height: 240px;
  object-fit: cover; /* L'image couvre toute la zone sans déformation */
}

.folder-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centrage horizontal et vertical */
  margin: 0;
  text-align: center;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Fond semi-transparent pour rendre le texte lisible */
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
}
.folder-selected {
  border-color: #239380; /* Couleur de la bordure pour une brique sélectionnée */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée pour la sélection */
  opacity: 1; /* Pleine opacité quand sélectionnée */
  filter: grayscale(0%); /* Pas de filtre pour les briques sélectionnées */
}
button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.DivGlobalFolders {
  display: flex;
  flex-wrap: wrap;
}

.carousel-title-div {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}

.carousel-title {
  font-size: 2em;
  text-align: left;
  margin-bottom: 20px;
  color: #2c3e50;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: relative;
  margin-left: 5%;
}
.carousel-title:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 5%;
  width: 60px;
  height: 3px;
  background-color: #489181 !important;
}


.div-info-icon {
  position: absolute; /* Positionné par rapport à son conteneur parent */
  top: 10px;
  right: 10px;
  border-radius: 50%; /* Cercle parfait autour de l'icône */
  padding: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  z-index: 10;
}

.info-icon {
  font-size: 28px; /* Taille de l'icône */
  color: #489181; /* Couleur bleue harmonieuse */
  transition: color 0.3s ease, transform 0.3s ease; /* Transitions pour le hover */
}

.div-info-icon:hover {
  transform: scale(1.1); /* Agrandit légèrement le cercle */
}


.info-icon:active {
  transform: scale(0.95); /* Réaction visuelle au clic */
}
</style>
