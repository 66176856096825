<template>
  <div class="flex-container">
    <div class="main marginMain">
      <Header
        :key="keyHeader"
        class="IndexNavbar"
        @blocName="blocName"
        :UserSelectedByTherapeute="user"
        @backToActivitySpacePro="handleBackToActivitySpacePro"
      ></Header>
      <div class="loader-container" v-if="isLoadingActivitySpace">
        <div class="loader-wrapper">
          <loaderComponent />
          <p class="loading-text">Chargement en cours..</p>
        </div>
      </div>
      <div class="main-container">
        <div class="content-container">
          <SuiviUtilisateur  v-if="blocSelected === 'suiviUtilisateur' && $NewVersionEnabled && SuiviDataUser" :SuiviDataUser="SuiviDataUser" :IdUser="IdSuiviDataUser" @isLoadingActivitySpace="handleIsLoadingActivitySpace"></SuiviUtilisateur>
            
          <listProtocol
            v-on:pop-up-contact="popUpContactEmitted"
            :ParamJeuGuidee="ParamJeuGuidee"
            :SeanceSelected="SeanceSelected"
            class="container-protocol"
            :idPatientTherapeute="objectIdPatient"
            v-on:is-display-programmes="onIsDisplayProgrammes"
            v-if="blocSelected == 'programmes' && isProgrammesDisplayed"
            :LinksOfUser="LinksOfUser"
          ></listProtocol>
          <listRessources
            :ParamJeu="ParamJeu"
            class="container-protocol"
            :idPatientTherapeute="objectIdPatient"
            v-if="blocSelected == 'programmes' && isDisplayRessources"
            :LinksOfUser="LinksOfUser"
          ></listRessources>
          <listEchelles
            v-on:pop-up-contact="popUpContactEmitted"
            class="container-protocol"
            :idPatientTherapeute="objectIdPatient"
            v-if="blocSelected == 'programmes' && isDisplayEchelles"
            :LinksOfUser="LinksOfUser"
          ></listEchelles>   
           <attributionsBrique v-if="blocSelected === 'createUser'"></attributionsBrique> 
          <jitsiMeetVue v-if="blocSelected === 'visio'"></jitsiMeetVue>
          <profil
            @displayHeader="handleDisplayHeader"
            v-if="blocSelected == 'profil'"
          ></profil>
          <beginPage
            @continue="handleContinue"
            v-if="blocSelected == 'beginPage'"
          ></beginPage>
          <beginPage2
            @continue="handleContinue2"
            v-if="blocSelected == 'beginPage2'"
          ></beginPage2>
          <beginPage3
            :idPatientTherapeute="objectIdPatient"
            @continue="handleContinue3"
            v-if="blocSelected == 'beginPage3'"
          ></beginPage3>
          <!-- {{ blocSelected }} -->
        </div>
        <div class="vertical-menu-container" key="menu" v-if="$NewVersionEnabled">
          
          <div class="menu-toggle" @click="toggleMenu" v-if="!isMenuClosed">
            <div class="bar"></div>
            <div class="bar"></div>
          </div>

          <div class="hamburger" @click="toggleMenu" v-if="isMenuClosed">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>

          
            <verticalMenu
              v-if="!isMenuClosed"
              @linkUser="handleIdlinkUser"
              @isLoadingActivitySpace="handleIsLoadingActivitySpace"
              @IdUser="handleIdUser"
              @ReloadHeader="handleReloadHeader"
              @UndisplayHeader="handleUnDisplayHeader"
              @showToastSuccess="notifySuccess"
              @showToastError="notifyError"
              @suivi="handleSuiviData"
              @edit="handleUpdateUser"
              @createUser="handleEmitCreateUser"
              :editUser="editUser"
              ref="verticalMenu"
              key="menu"
            />
          
        </div>
      </div>
    </div>
    <notif-toast ref="notifToast"></notif-toast>
    <ContactIcon :idUserMsg="ParamMsg" ref="childRef"></ContactIcon>
    <!-- <MessagerieBottom></MessagerieBottom> -->
    <!-- <VisioBottom></VisioBottom> -->
    <MajNotification
      v-if="maj.length > 0 && showNotification"
      :maj="maj"
    ></MajNotification>
    <FooterMailBienEtreVue v-if="showModalBienEtre"></FooterMailBienEtreVue>
    <FooterMailTechnique v-if="showModalTechnique"></FooterMailTechnique>
    <FooterA
      class="sticky-footer"
      @openModal="handleOpenModal"
      @openModalTechnique="handleOpenModalTechnique"
    ></FooterA>
  </div>
</template>
<script>
import Header from "@/components/accueilV2/Header2.vue";
import HeaderForPatient from "@/components/espaceGestionV2/HeaderForPatient.vue";
import loaderComponent from "@/components/loader/loaderClassic.vue";

import attributionsBrique from "@/components/espaceGestionV2/attributionsBrique.vue";

import SuiviUtilisateur from "@/components/espaceGestionV2/SuiviUtilisateur.vue";

// import FooterA from "@/components/FooterA.vue";
import FooterA from "@/components/accueilV2/Footer2.vue";
import profil from "@/components/profil.vue";
import listProtocol from "@/components/SymbioCenter_Activites/ListProtocolNew.vue";
import listRessources from "@/components/SymbioCenter_Activites/ListRessourcesNew.vue";
import listEchelles from "@/components/SymbioCenter_Activites/ListEchellesNew.vue";
// import MessagerieBottom from "@/components/chat/MessagerieBottom.vue";
// import VisioBottom from "@/components/visio/VisioBottom.vue";
// import doughnut from "@/components/doughnut.vue";
// import VideoChat from "@/components/visio/VideoChat.vue";
import { getUserById } from "@/services/utilisateurs/getUserById";
import jitsiMeetVue from "@/components/visio/meetingComponent.vue";
// import testAppelBluetoothVue from "../components/testAppelBluetooth.vue";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import { verificationToken } from "@/services/token/verificationToken.js";
import MajNotification from "@/components/notification/MajNotification.vue";
import FooterMailBienEtreVue from "@/components/accueilV2/FooterMailBienEtre.vue";
import FooterMailTechnique from "@/components/accueilV2/FooterMailTechnique.vue";
import ContactIcon from "@/components/contact/contactIcon.vue";
import WebSocketService from "@/services/socket/WebSocketService.js";

import verticalMenu from "@/components/espaceGestionV2/verticalMenu.vue";

import beginPage from "@/components/accueilV2/beginPage.vue";
import beginPage2 from "@/components/accueilV2/beginPage2.vue";
import beginPage3 from "@/components/accueilV2/beginPage3.vue";

import NotifToast from "@/components/espaceGestionV2/NotifToast.vue";

import AuthService from "@/services/utilisateurs/authService.js"; // Assurez-vous du bon chemin d'import

// import router
import router from "@/router";
import { inject } from "vue";

import {setGlobalVariables} from "@/services/utilisateurs/globalSettingsUser.js";
import { getSuiviForUser } from "@/services/utilisateurs/getSuiviForUser.js";

export default {
  name: "Accueil",
  components: {
    FooterA,
    listEchelles,
    profil,
    listProtocol,
    listRessources,
    jitsiMeetVue,
    // MessagerieBottom,
    Header,
    MajNotification,
    // VisioBottom,
    FooterMailBienEtreVue,
    FooterMailTechnique,
    beginPage,
    beginPage2,
    beginPage3,
    ContactIcon,
    verticalMenu,
    loaderComponent,
    HeaderForPatient,
    SuiviUtilisateur,
    NotifToast,
    attributionsBrique,
  },
  data() {
    return {
      UserSelectedByTherapeute: "",
      blocSelected: "programmes",
      element_user_local: [],
      objectIdPatient: "",
      isProgrammesDisplayed: true,
      isDisplayRessources: true,
      isDisplayEchelles: true,
      foldersBrique: [],
      user: null,
      bgColor: null,
      bgImage: null,
      typeT: null,
      linkedObjectPatient: null,
      Cgu: null,
      lastExercice: null,
      keyProfile: 0,
      keyHeader: 0,
      maj: [],
      showNotification: true,
      showModalTechnique: false,
      showModalBienEtre: false,
      ParamJeu: null,
      ParamJeuGuidee: null,
      SeanceSelected: null,
      showPopUpContact: false,
      ParamMsg: null,
      LinksOfUser: [],
      isLoadingActivitySpace: false,
      isMenuClosed: false,
      SuiviDataUser: null,
      editUser: false,
    };
  },
  computed: {
    isTherapeute() {
      try {
        return !!Parse.User.current().attributes.therapeute;
      } catch (error) {
        console.error("Erreur lors de la vérification de therapeute:", error);
        return false;
      }
    },
  },
  async created() {
    
    setGlobalVariables();
    ParseConfig();
    try {
      this.recupParamJeu();
      this.recupParamJeuGuidee();
      this.recupParamMsg();
    } catch (error) {
      console.log(error);
    }
    try {
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      this.objectIdPatient = urlParams.get("objectId");
      console.log("objectIdPatient ++", this.objectIdPatient);
    } catch (error) {
      console.log(error);
    }
    if (localStorage.getItem("idUserForSave")) {
      this.user = await getUserById(localStorage.getItem("idUserForSave"));
      console.log('IdUserForSave ++',localStorage.getItem("idUserForSave"));
      this.objectIdPatient = localStorage.getItem("idUserForSave");
    }
    try {
      var user = Parse.User.current();
      if (user.attributes.cgu !== true) {
        console.log("CGU non acceptées");
        this.blocSelected = "beginPage";
      }
    } catch (error) {
      //console.log(error)
    }

    try {
      this.verifBfp();
    } catch (error) {
      console.log(error);
    }
    this.getLatestMaj();
  },
  watch: {
    imageTexture() {
      if (this.imageTexture) {
        this.isCardAjoutTexture = true;
      }
    },
    async verifToken() {
      var token = localStorage.getItem("token");
      var loginTimestamp = parseInt(localStorage.getItem("dateConnexion"), 10);
      var result = await verificationToken(token);
      if (Date.now() - loginTimestamp > 12 * 60 * 60 * 1000) {
        // si le token est trop vieux, on le supprime
        localStorage.removeItem("token");
        localStorage.removeItem("idUserForSave");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        await this.actionsLogOut();
        router.push("/connexion");
      }
      if (result == false) {
        //si le token est mauvais, on le supprime
        localStorage.removeItem("token");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        await this.actionsLogOut();
        router.push("/connexion");
      }
    },
    imageBackground() {
      if (this.imageBackground) {
        this.isCardAjoutBackground = true;
      }
    },

    user: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  async mounted() {
    ParseConfig();
    // this.callSocketOnline();
  },

  methods: {
    async handleIdUser(id) {
      this.objectIdPatient = id;
      this.blocSelected = "programmes";
      //console.log('Création du cookie :', e.objectId)
      document.cookie =
        "idUserForSave=" + this.objectIdPatient + ";path=/;max-age=3600";
      this.keyHeader++;
      console.log(
        "%cUtilisateur récupéré via Espace Gestion V2:",
        "color: blue;",
        this.user
      );
    },
    handleEmitCreateUser() {
      this.blocSelected = "createUser";
    },
    testIdPatient() {
      console.log("objectIdPatient", this.objectIdPatient);
    },
    notifySuccess(msg) {
      this.$refs.notifToast.showToast(msg, "success");
    },
    notifyError(msg) {
      this.$refs.notifToast.showToast(msg, "error");
    },
    handleUpdateUser(user) {
      console.log("handleUpdateUser");
      this.isMenuClosed = false;

      this.editUser = true;
    },
    handleUnDisplayHeader(data) {
        this.isMenuClosed = true;
    },
    async handleSuiviData(user) {
      console.log("handleSuiviData", user);
      this.handleIsLoadingActivitySpace(true);
      this.SuiviDataUser = await getSuiviForUser(user.id);
      this.IdSuiviDataUser = user.id;
      console.log("Suivi Data", this.SuiviDataUser,this.objectIdPatient);
      this.blocSelected="suiviUtilisateur"
      this.handleIsLoadingActivitySpace(false);
    },
    handleBackToActivitySpacePro() {
      this.isMenuClosed = false;
      const user = "activitySpace";
      this.blocSelected = "programmes";
      this.editUser = false;
      setTimeout(() => {
        this.$refs.verticalMenu.selectUser(user);
      }, 500);
    },
    toggleMenu() {
      if(this.editUser){
        this.editUser = false;
      }
      else{
        this.isMenuClosed = !this.isMenuClosed;
      }
    },
    async generateToken() {
      AuthService.generateToken(Parse.User.current().id);
    },
    async generateSession() {
      AuthService.getSessionToken(Parse.User.current().attributes.loginToken);
    },
    handleIsLoadingActivitySpace(value) {
      this.isLoadingActivitySpace = value;
    },
    handleIdlinkUser(link) {
      this.LinksOfUser = link;
    },
    callSocketOnline() {
      try {
        const webSocketService = new WebSocketService();
        // Pour se connecter au WebSocket
        webSocketService.connectToWebSocket();
        // Pour envoyer l'ID de l'utilisateur
        const userId = Parse.User.current().id;
        webSocketService.sendUserId(userId);
      } catch (error) {
        console.error("Erreur lors de la connexion au WebSocket :", error);
      }
    },
    handleDisplayProfile() {},
    handleDisplayHeader() {
      this.keyHeader++;
    },
    handleReloadHeader(value) {
      this.keyHeader++;
    },
    handleClosePopUpContact() {
      this.showPopUpContact = false;
    },
    handleDisplayContactPopUp() {
      this.showPopUpContact = !this.showPopUpContact;
    },
    async verifBfp() {
      var userId =
        this.objectIdPatient || localStorage.getItem("idUserForSave");
      if (userId) {
        this.blocSelected = await this.checkUser(userId);
      } else {
        this.blocSelected = await this.checkUser(Parse.User.current().id);
      }
    },
    async checkUser(userId) {
      try {
        var user = await getUserById(userId);
        if (user.QuestionnaireBegin) {
          // let results = await Parse.Cloud.run("getUserFilesDone", {
          //   userId: userId,
          // });
          let results = await this.findFilesDone(user);
          if (results.length > 0) {
            console.log("resultat supérieur à 0");
            return "programmes";
          } else if (Parse.User.current().attributes.cgu === true) {
            console.log("resultat inférieur à 0");
            return "beginPage3";
          } else {
            console.log("Questionnaire Begin No");
            return "beginPage";
          }
        } else if (Parse.User.current().attributes.cgu === true) {
          console.log("Questionnaire Begin No");
          return "programmes";
        } else {
          console.log("Questionnaire Begin No");
          return "beginPage";
        }
      } catch (error) {
        console.error(
          "Erreur lors de la vérification de l'utilisateur :",
          error
        );
        return "programmes";
      }
    },
    findFilesDone(user) {
      let results = [];
      var UserQuery = Parse.Object.extend("User");
      var query = new Parse.Query(UserQuery);
      console.log("user.id", user.id);
      query.equalTo("objectId", user.objectId);
      var FileDoneQuery = Parse.Object.extend("file_done");
      var queryFileDone = new Parse.Query(FileDoneQuery);
      queryFileDone.matchesQuery("user", query);
      queryFileDone.equalTo("bfp", true);
      results = queryFileDone.find();
      return results;
    },
    handleContinue() {
      this.blocSelected = "beginPage2";
    },
    recupParamMsg() {
      // console.log("recupParamJeu");
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      this.ParamMsg = urlParams.get("idUserMsg");
    },
    recupParamJeu() {
      // console.log("recupParamJeu");
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      this.ParamJeu = urlParams.get("jeu");
      // console.log("ParamJeu", this.ParamJeu);
    },
    recupParamJeuGuidee() {
      // console.log("recupParamJeuGuidee");
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      // console.log("urlParams", urlParams);
      this.ParamJeuGuidee = urlParams.get("jeu_guidee");
      // console.log("ParamJeu", this.ParamJeuGuidee);
      this.SeanceSelected = urlParams.get("SeanceSelected");
      // console.log("SeanceSelected", this.SeanceSelected);
    },
    handleContinue2() {
      if (Parse.User.current().attributes.QuestionnaireBegin) {
        this.blocSelected = "beginPage3";
      } else {
        this.blocSelected = "programmes";
      }
    },
    popUpContactEmitted() {
      this.$refs.childRef.childMethod();
    },
    handleContinue3() {
      this.blocSelected = "programmes";
    },
    blocName(e) {
      this.blocSelected = e;
    },
    handleOpenModal() {
      this.showModalBienEtre = true;
    },
    handleOpenModalTechnique() {
      this.showModalTechnique = true;
    },
    onIsDisplayProgrammes(value) {
      this.isProgrammesDisplayed = value;
    },

    async checkIfAcknowledged() {
      const MajStatut = Parse.Object.extend("maj_user_statuts");
      const query = new Parse.Query(MajStatut);
      query.equalTo("user", Parse.User.current());
      query.equalTo("maj", this.maj[0]);

      try {
        const results = await query.first();
        if (results) {
          this.showNotification = false;
        }
      } catch (error) {
        console.log(
          "Erreur lors de la vérification du statut de la mise à jour:",
          error
        );
      }
    },
    async getLatestMaj() {
      ParseConfig();
      const Maj = Parse.Object.extend("maj");
      const query = new Parse.Query(Maj);
      query.descending("createdAt");
      query.limit(1);

      try {
        this.maj = await query.find();
        this.checkIfAcknowledged();
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des mises à jour:",
          error
        );
      }
    },
    getFoldersByTheme(monTheme) {
      return this.foldersBrique.filter((el) => {
        if (el.themes.length < 2) {
          return el.themes[0].objectId === monTheme;
        }
        return (
          el.themes[0].objectId === monTheme ||
          el.themes[1].objectId === monTheme
        );
      });
    },
  },
};
</script>

<style>
html {
  max-height: 100% !important;
  max-width: 100% !important;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: Roboto, Helvetica Neue, sans-serif;
  background-color: #f3f3f3;
}
.container {
  min-height: 60vh;
  margin-bottom: -50px;
  padding-bottom: 50px;
}
@media (min-width: 1800px) {
  .container {
    min-height: 70vh;
  }
}
.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

/* Styles du footer */
.sticky-footer {
  flex-shrink: 0;
}

.IndexNavbar {
  z-index: 1000;
}

.marginMain {
  min-height: 100vh;
}

.container-protocol {
  margin-bottom: 15px;
}
.main-container {
  display: flex;
}

.content-container {
  flex: 1; /* Prend toute la largeur disponible sauf celle réservée pour le menu */
  overflow-y: hidden; /* Cache la barre de scroll */
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  position: relative;
  min-height: 30rem;
}

.vertical-menu-container {
  border-left: 1px solid #ddd; /* Optionnel : ajoute une bordure à gauche du menu */
  overflow-y: hidden; /* Cache la barre de scroll */
  position: relative;
  background: white;
  min-height:100vh;
  min-width: 30vh;
}

/* Styles pour masquer les barres de défilement */
.content-container::-webkit-scrollbar,
.vertical-menu-container::-webkit-scrollbar {
  display: none; /* Cache la barre de scroll pour les navigateurs Webkit */
}

.content-container,
.vertical-menu-container {
  -ms-overflow-style: none; /* Pour Internet Explorer et Edge */
  scrollbar-width: none; /* Pour Firefox */
}

/* Scroll personnalisé : utilisation de padding et de masquage */
.content-container::before,
.vertical-menu-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll; /* Active le scroll sans montrer la barre */
  padding-right: 20px; /* Espace pour compenser la barre de scroll cachée */
  margin-right: -20px; /* Décale le scroll vers l'intérieur */
  pointer-events: none; /* Empêche l'interaction directe avec cet élément */
  z-index: -1;
}

.content-container::after,
.vertical-menu-container::after {
  content: "";
  display: block;
  height: 100%; /* Remplit tout le conteneur pour s'assurer du scroll */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.loader-wrapper {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-text {
  color: #239380; /* Utilisation de votre code couleur */
  font-size: 2rem;
  font-weight: 500;
}

.menu-toggle {
  right: 0px;
  /* margin-left: auto; */
  width: 40px;
  height: 40px;
  position: absolute;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

/* Barres de la croix */
.menu-toggle .bar {
  position: absolute;
  width: 20px;
  height: 4px;
  background-color: #333;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.menu-toggle .bar:first-child {
  transform: rotate(45deg);
}

.menu-toggle .bar:last-child {
  transform: rotate(-45deg);
}

.menu-toggle:hover {
  /*zoom*/
  transform: scale(1.1);
}

.menu-toggle:hover .bar {
  background-color: #239380;
}

/* Conteneur du bouton hamburger */
.hamburger {
  width: 40px;
  height: 30px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: transform 0.3s ease;
}

.hamburger:hover {
  transform: scale(1.1);
}

/* Barres du hamburger */
.hamburger .bar {
  width: 30px;
  height: 4px;
  background-color: #333;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.hamburger:hover .bar {
  background-color: #239380;
}

/* Transformation en croix lors de l'activation */
.hamburger.active .bar:nth-child(1) {
  transform: rotate(20deg) translate(2px, 2px);
}

.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.active .bar:nth-child(3) {
  transform: rotate(-20deg) translate(2px, -2px);
}

.vertical-menu-container verticalMenu {
  max-height: 0;
  transform: translateY(-20px);
  opacity: 0;
  transition: max-height 0.5s ease, transform 0.5s ease, opacity 0.5s ease;
}

/* Styles lorsque le menu est ouvert */
.vertical-menu-container.menu-open verticalMenu {
  max-height: 500px; /* Ajuster cette valeur selon la taille de ton menu */
  transform: translateY(0);
  opacity: 1;
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter {
  transform: translateX(-100%);
}

.slide-leave-to {
  transform: translateX(100%);
}
</style>
