<template>
  <div class="header">
    <div class="logo">
      <!-- <img :src="logoUrl" alt="Symbiocenter" /> -->
      <img src="../../../../assets/img/logo_symbiofi.png" alt="Symbiocenter" />

    </div>
    <div class="user-actions">
      <div
        class="notification-wrapper notifPlacement"
        @click="toggleNotificationDiv"
      >
        <b-icon icon="bell" class="notification-icon"></b-icon>
        <span class="notification-count" v-if="unreadCount">
          {{ unreadCount }}
        </span>
      </div>
      <div class="notification-div" v-if="showNotificationDiv">
        <NotificationDiv
          :notifications="notifications"
          @notifDetails="handleNotifDetails"
          @markAllAsRead="handlemarkAllAsRead()"
          @updateLastNews="HandleUpdateLastNews()"
        />
      </div>
      <!-- <buttonHeader  :user="user"
      :namePatient="namePatient"
      :idUserForSave="idUserForSave"
      :UserCabinetTherapeute="UserCabinetTherapeute"
      @handleButtonClick="handleButtonClickNew"
    ></buttonHeader> -->
    <buttonHeader  @handleButtonClick="handleButtonClickNew" :idUserForSave="idUserForSave" :namePatientProps="namePatient"></buttonHeader>
      <button @click="deconnexion()" class="logout-btn">
        <b-icon icon="logout" class="logout-icon"></b-icon>
      </button>
    </div>
    <div class="popup" v-if="showPopup">
      <NotificationDetails
        :selectedNotification="selectedNotification"
        @close-popup="handleClosePopup"
      ></NotificationDetails>
    </div>
  </div>
</template>
<script>
import NotificationComponent from "@/components/NotificationComponent.vue";
const User = createNamespacedHelpers("user");
import { createNamespacedHelpers } from "vuex";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import router from "@/router";
import { getUserById } from "@/services/utilisateurs/getUserById.js";
import { verificationToken } from "@/services/token/verificationToken.js";
import moment from "moment";
import { URL_FRONT } from "@/configuration/urlConf.js";
import NotificationDiv from "../../components/notification/Notification.vue";
import NotificationDetails from "../../components/notification/NotificationDetails.vue";
import buttonHeader from "./buttonHeader.vue";

export default {
  name: "Header",
  components: {
    NotificationDiv,
    NotificationDetails,
    buttonHeader,
  },
  data() {
    return {
      urlFront: URL_FRONT,
      showMobileMenu: false,
      showLogo: false,
      scrolledNav: null,
      mobile: false,
      mobileNav: null,
      windowWidth: null,
      mincredits: 0,
      appParam: null,
      daysLeft: null,
      isDisplay: true,
      userGroup: {},
      logoPro: null,
      blocName: null,
      isDisablelogo: true,
      isActive: true,
      blocSelected: "programmes",
      mobileMenu: false,
      screenWidth: 0,
      objectIdPatient: null,
      namePatient: null,
      showMenuList: false,
      afficherProgrammes: "",
      notifications: [],
      modalActive: false,
      selectedNotification: {},
      UserCabinetTherapeute: null,
      idUserForSave: null,
      cguActived: false,
      modalActive: false,
      showNotificationDiv: false,
      notificationsTest: [
        // Exemple de données de notification
        { id: 1, icon: "mail", message: "New email received", time: "2m ago" },
      ],
      currentNotifDetails: "",
      showPopup: false,
      isTherapeute: false,
      logoUrl:null,
    };
  },
  created() {
    console.log('%cAccès de l\'utilisateur: ' + this.$NewVersionEnabled, 'color: blue');
    var user = Parse.User.current();
    try {
      if (user.attributes.cgu) {
        this.cguActived = true;
      } else {
        this.cguActived = false;
      }
    } catch (e) {
      this.deconnexion();
    }
    
      // this.fetchLogo();

    try {
      if (user.attributes.therapeute) {
        this.isTherapeute = true;
      } else {
        this.isTherapeute = false;
      }
    } catch (e) {
      //console.log("Erreur lors de la vérification...");
    }
  },
  props: {
    UserSelectedByTherapeute: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...User.mapGetters(["group", "user"]),
    unreadCount() {
      if (this.isTherapeute) {
        return this.notifications.filter(
          (notif) => !notif.attributes.isReadUserPro
        ).length;
      } else {
        return this.notifications.filter(
          (notif) => !notif.attributes.isReadUser
        ).length;
      }
    },
  },
  methods: {
    ...User.mapActions({
      actionsLogOut: "logOut",
      resetPassword: "resetPassword",
    }),
    toggleNotificationDiv() {
      this.showNotificationDiv = !this.showNotificationDiv;
    },
    // async fetchLogo() {
    //   try {
    //     const groupId = Parse.User.current().attributes.group.id; // Récupérez l'ID du groupe de l'utilisateur actuel
    //     const result = await Parse.Cloud.run('getLogoFromUserGroup', { groupId });
    //     this.logoUrl = result.logoUrl; // Mettre à jour l'URL du logo pour l'affichage
    //     console.log('Logo récupéré avec succès:', result.logoUrl);
    //   } catch (error) {
    //     console.error('Erreur lors de la récupération du logo:', error);
    //   }
    // },
    handleClosePopup() {
      this.showPopup = false;
    },
   handlemarkAllAsRead () {
      this.maskAllAsRead();
    },
    HandleUpdateLastNews() {
      this.getLastNews();
    },
    async maskAllAsRead() {
      //console.log('Mettre lu à toutes les notifications...')
      try {
        const ObjetNotif = Parse.Object.extend("notification");
        const QueryNotif = new Parse.Query(ObjetNotif);
        const ObjetUser = Parse.Object.extend("User");
        const QueryUser = new Parse.Query(ObjetUser);

        QueryUser.equalTo("objectId", this.user.objectId);

        if (this.user.therapeute) {
          QueryNotif.matchesQuery("user_pro", QueryUser);
        } else {
          QueryNotif.matchesQuery("user", QueryUser);
        }
        QueryNotif.equalTo("isRead", false); // Assuming the attribute to check if a notification is read or not is 'isRead'
        
        const notificationsToMarkAsRead = await QueryNotif.find();

        for (let notif of notificationsToMarkAsRead) {
          if(this.user.therapeute)
          {
            //console.log('Mettre lu à la notification de l\'utilisateur pro...')
          notif.set("isReadUserPro", true); // Assuming the attribute to set a notification as read is 'isRead'
        }
        else
        {
          //console.log('Mettre lu à la notification de l\'utilisateur...')
          notif.set("isReadUser", true); // Assuming the attribute to set a notification as read is 'isRead'
        }
      }
        await Parse.Object.saveAll(notificationsToMarkAsRead); // Save all changes in one batch

        // Optionally, refresh your local notifications list
        this.getLastNews();
      } catch (error) {
        console.error(
          `Error in marking all notifications as read: ${error.message}`
        );
      }
    },
    removeNotification(id) {},
    deconnexion() {
      try {
        this.actionsLogOut();
      } catch (error) {
        //console.log(error);
      }
      window.location.href = "/connexion";
    },
    handleButtonClick() {
      if (
        this.user.therapeute &&
        this.namePatient == null &&
        this.idUserForSave == null
      ) {
        // Logique pour le cas 1
        // Par exemple, appelez une fonction resetStorage() ici
        try {
          this.resetStorage();
        } catch (error) {
          //console.log(error);
        }
        this.$router.push("/utilisateurs");
      } else if (this.user.therapeute && this.namePatient) {
        // Logique pour le cas 2
        // Par exemple, redirigez ou effectuez une autre action
        this.$router.push("/utilisateurs");
      } else if (this.user.therapeute && this.idUserForSave) {
        // Logique pour le cas 3
        // Par exemple, redirigez ou effectuez une autre action

        this.$router.push("/utilisateurs");
      } else {
        // Logique pour le cas par défaut
        // Par exemple, appelez la fonction afficheBloc('profil')
        this.afficheBloc("profil");
      }
    },
    handleButtonClickNew()
    {
      if(!this.$NewVersionEnabled)
      {
        this.handleButtonClick();
        return;
      }
      console.log("HandleClickNew",this.user.therapeute,this.namePatient,this.idUserForSave);
      if (
        this.user.therapeute &&
        this.namePatient == null &&
        this.idUserForSave == null|| this.user.therapeute &&
        this.namePatient == null &&
        this.idUserForSave === ""
      ) {
        try {
          this.resetStorage();
        } catch (error) {
          //console.log(error);
        }
        this.$router.push("/utilisateurs");
      } else if (this.user.therapeute && this.namePatient) {
        this.$router.push("/utilisateurs");
      } else if (this.user.therapeute && this.idUserForSave) {
        this.$emit("backToActivitySpacePro");
      } else {
        this.afficheBloc("profil");
      }
    },
    async getLastNews() {
      try {
        const ObjetNotif = Parse.Object.extend("notification");
        const QueryNotif = new Parse.Query(ObjetNotif);
        const ObjetUser = Parse.Object.extend("User");
        const QueryUser = new Parse.Query(ObjetUser);
        QueryUser.equalTo("objectId", this.user.objectId);
        if (this.user.therapeute) {
          QueryNotif.matchesQuery("user_pro", QueryUser);
        } else {
          QueryNotif.matchesQuery("user", QueryUser);
          QueryNotif.notEqualTo("type", "Questionnaire BFP");

        }
        QueryNotif.descending("updatedAt");
        // QueryNotif.limit(3);
        QueryNotif.include("user");
        QueryNotif.include("user_pro");
        //inclure meeting_events
        QueryNotif.include("meeting_event");
        const actualites = await QueryNotif.find();
        this.notifications = actualites;
      } catch (error) {
        console.error(
          `Erreur lors de la récupération des actualités : ${error.message}`
        );
      }
    },
    handleAccepted(response) {
      this.getLastNews();
      console.log("Accepted", response);
    },
    handleDeclined(response) {
      this.getLastNews();
      console.log("Declined", response);
    },
    recupIdPatient(idLocal) {
      ParseConfig();
      const objectUser = Parse.Object.extend("User");
      const queryUser = new Parse.Query(objectUser);
      queryUser.equalTo("objectId", idLocal);
      const user = queryUser.find().then((e) => {
        this.element_user_local = e;
        if (this.element_user_local[0]) {
          this.namePatient = this.element_user_local[0].attributes.lastname;
        }
      });
      console.log("user", user);
    },
    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length == 2) return parts.pop().split(";").shift();
    },
    async resetStorage() {
      //reset le localStorage idUserForSave
      localStorage.removeItem("idUserForSave");
      var token = localStorage.getItem("token");
      var loginTimestamp = parseInt(localStorage.getItem("dateConnexion"), 10);
      var result = await verificationToken(token);
      if (Date.now() - loginTimestamp > 12 * 60 * 60 * 1000) {
        // si le token est trop vieux, on le supprime
        localStorage.removeItem("token");
        localStorage.removeItem("idUserForSave");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        await this.actionsLogOut();
        router.push("/connexion");
      }
      if (result == false) {
        //si le token est mauvais, on le supprime
        localStorage.removeItem("token");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        await this.actionsLogOut();
        router.push("/connexion");
      }
    },
    handleNotifDetails(notif) {
      this.selectedNotification = notif;
      this.showPopup = true;
    },
    afficheBloc(nameSelected) {
      this.blocSelected = nameSelected;
      this.$emit("blocName", nameSelected);
    },
    async UpdateConnexion(x, y) {
      try {
        await ParseConfig();
        const today = moment().toDate();
        const userObject = Parse.Object.extend("User");
        const userGroupObject = Parse.Object.extend("user_group");

        const userQuery = new Parse.Query(userObject);
        const userGroupQuery = new Parse.Query(userGroupObject);

        userGroupQuery.equalTo("objectId", x);
        userQuery.equalTo("objectId", y);

        const userFind = await userQuery.first();
        const userGroupFind = await userQuery.first();
        if (today) {
          userFind.set("derniere_connexion", today);
        }
        if (
          userFind.attributes.LicenceActive === false ||
          userFind.attributes.LicenceActive === undefined
        ) {
          userFind.set("LicenceActive", true);
          if (this.user.duree_acces === 1000) {
            const date_debut_licence = moment(
              this.user.date_debut_licence
            ).toDate();
            const date_fin_licence = moment(this.user.date_debut_licence)
              .add(1, "month")
              .toDate();
            if (moment().isSameOrAfter(date_fin_licence)) {
              if (this.group.credits - 1 >= this.mincredits) {
                userGroupFind.set("credits", this.group.credits - 1);
              } else {
                userGroupFind.set("credits", this.mincredits);
              }
              userGroupFind.set("date_debut_licence", moment().toDate());
            } else {
              if (this.group.credits - 1 >= this.mincredits) {
                userGroupFind.set("credits", this.group.credits - 1);
              } else {
                userGroupFind.set("credits", this.mincredits);
              }
            }
          } else {
            if (this.group.credits - this.user.duree_acces >= this.mincredits) {
              userGroupFind.set(
                "credits",
                this.group.credits - this.user.duree_acces
              );
            } else {
              userGroupFind.set("credits", this.mincredits);
            }
          }
        }

        if (userFind.attributes.LicenceActive === true) {
          if (this.user.duree_acces === 1000) {
            const date_debut_licence = moment(
              this.user.date_debut_licence
            ).toDate();
            const date_fin_licence = moment(this.user.date_debut_licence)
              .add(1, "month")
              .toDate();
            if (moment().isSameOrAfter(date_fin_licence)) {
              if (this.group.credits - 1 >= this.mincredits) {
                userGroupFind.set("credits", this.group.credits - 1);
              } else {
                userGroupFind.set("credits", this.mincredits);
              }
              userGroupFind.set("date_debut_licence", moment().toDate());
            }
          }
        }
        await userFind.save();
        await userGroupFind.save();
      } catch (error) {}

      Parse.masterKey = "myMasterKey";
      const today = moment().toDate();
      const QueryUser = new Parse.Query(Parse.User);
      const ObjectuserGroup = Parse.Object.extend("user_group");
      const QueryuserGroup = new Parse.Query(ObjectuserGroup);
      QueryUser.equalTo("objectId", x);
      QueryuserGroup.equalTo("objectId", y);

      const ResponseUser = await QueryUser.first();
      const FinduserGroup = await QueryuserGroup.first();

      ResponseUser.set("derniere_connexion", today);

      // ----------------- Update user_group -----------------

      if (
        (ResponseUser && ResponseUser.attributes.LicenceActive === false) ||
        ResponseUser.attributes.LicenceActive === undefined
      ) {
        ResponseUser.set("LicenceActive", true);
        if (this.user.duree_acces === 1000) {
          const date_debut_licence = moment(
            this.user.date_debut_licence
          ).toDate();
          const date_fin_licence = moment(this.user.date_debut_licence)
            .add(1, "month")
            .toDate();
          if (moment().isSameOrAfter(date_fin_licence)) {
            if (this.group.credits - 1 >= this.mincredits) {
              FinduserGroup.set("credits", this.group.credits - 1);
            } else {
              FinduserGroup.set("credits", this.mincredits);
            }
            FinduserGroup.set("date_debut_licence", moment().toDate());
          } else {
            if (this.group.credits - 1 >= this.mincredits) {
              FinduserGroup.set("credits", this.group.credits - 1);
            } else {
              FinduserGroup.set("credits", this.mincredits);
            }
          }
        } else {
          if (this.group.credits - this.user.duree_acces >= this.mincredits) {
            FinduserGroup.set(
              "credits",
              this.group.credits - this.user.duree_acces
            );
          } else {
            FinduserGroup.set("credits", this.mincredits);
          }
        }
      }

      if (ResponseUser.attributes.LicenceActive === true) {
        if (this.user.duree_acces === 1000) {
          const date_debut_licence = moment(
            this.user.date_debut_licence
          ).toDate();
          const date_fin_licence = moment(this.user.date_debut_licence)
            .add(1, "month")
            .toDate();
          if (moment().isSameOrAfter(date_fin_licence)) {
            if (this.group.credits - 1 >= this.mincredits) {
              FinduserGroup.set("credits", this.group.credits - 1);
            } else {
              FinduserGroup.set("credits", this.mincredits);
            }
            FinduserGroup.set("date_debut_licence", moment().toDate());
          }
        }
      }
      await ResponseUser.save({}, { useMasterKey: true });
      await FinduserGroup.save({}, { useMasterKey: true });
    },
    toggleNotifications() {
      this.modalActive = !this.modalActive;
    },
  },
  async mounted() {
    this.idUserForSave = this.getCookie("idUserForSave");
    if (this.idUserForSave) {
      this.UserCabinetTherapeute = await getUserById(this.idUserForSave);
    }

    // if (!this.user.therapeute) {
    //   this.UpdateConnexion(this.user.objectId, this.group.objectId);
    // }
    if(!this.UserCabinetTherapeute)
    {
      this.getLastNews();
    }
    setTimeout(() => {
      this.showMenuList = true;
    }, 1000);
    if (this.user.therapeute) {
      try {
        this.logoPro = this.$store.state.user.group.logo.url();
      } catch (e) {
        if (this.group.logo) {
          this.logoPro = this.group.logo.url;
        }
      }
    }
    const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
    this.objectIdPatient = urlParams.get("objectId");
    this.recupIdPatient(this.objectIdPatient);
    try {
      ParseConfig();
      await Parse.User.current();
      var queryAppParam = new Parse.Query("app_param");
      this.appParam = await queryAppParam.first();

      var dt = new Date();
      if (
        Parse.User.current().attributes.therapeute &&
        Parse.User.current().attributes.group.attributes.subscription_end_date
      ) {
        var tempsRestant =
          Parse.User.current().attributes.group.attributes.subscription_end_date.getTime() -
          dt.getTime();

        var tempsRestants = Math.trunc(tempsRestant / 86400000);
        if (tempsRestants < 0) {
          this.daysLeft = "Acces limité";
        } else if (tempsRestants > 0 && 31 > tempsRestants) {
          this.daysLeft = tempsRestants + " jours restants";
        }
      } else {
        this.daysLeft = null;
      }
    } catch (error) {
      await this.actionsLogOut();
      router.go("/connexion");
    }
  },
};
</script>
<style lang="scss" scoped>
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 45px;
  background-color: #ffffff;
  color: #333;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid #e7e7e7;

  > * {
    animation: fadeInUp 0.6s forwards;
    animation-delay: 0.2s;
  }

  .logo {
    img {
      max-height: 50px;
      width: auto;
      transition: 0.3s;

      &:hover {
        transform: scale(1.05);
      }

      // Ajouter un délai d'animation pour une séquence d'apparition
      animation-delay: 0.3s;
    }
  }
  .user-actions {
    display: flex;
    align-items: center;
    z-index: 100;

    .user-btn {
      margin-right: 24px; // Espacement entre les deux boutons
    }
  }

  .user-btn {
    display: flex;
    align-items: center;
    background: linear-gradient(135deg, #489181, #3b7a6d);
    color: #ffffff;
    padding: 12px 24px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.4s;
    font-weight: 600;
    font-size: 16px;
    box-shadow: 0 4px 15px rgba(72, 145, 129, 0.2);

    &:hover {
      background: linear-gradient(135deg, #3b7a6d, #489181);
      transform: translateY(-2px);
    }

    .user-icon {
      margin-right: 12px;
      font-size: 20px;
    }

    // Ajouter un délai d'animation pour une séquence d'apparition
    animation-delay: 0.4s;
  }

  @media (max-width: 850px) {
    padding: 20px 30px;

    .logo img {
      max-height: 35px;
    }

    .user-btn {
      padding: 10px 20px;
      font-size: 14px;

      .user-icon {
        margin-right: 10px;
        font-size: 18px;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 15px 20px;

    .logo img {
      max-height: 25px;
    }

    .user-btn {
      padding: 4px 8px;
      font-size: 10px;

      .user-icon {
        margin-right: 4px;
        font-size: 10px;
      }
    }
    .logout-btn {
      padding: 4px 8px;
      font-size: 10px;
      .logout-icon {
        font-size: 10px;
      }
    }
    .user-btn {
      margin-right: 10px !important;
    }
  }
}

// Amélioration du bouton de déconnexion pour correspondre au bouton utilisateur
.logout-btn {
  background: #f1f1f1;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background: #ccc;
  }

  .logout-icon {
    font-size: 20px;
  }
}
.user-btn:hover,
.logout-btn:hover {
  background: #3b7a6d;
  color: #fff;
}
.IndexNavbar {
  z-index: 400;
}
.notification-wrapper {
  position: relative;
  display: inline-block; /* Makes the wrapper adjust to the content size */
  cursor: pointer; /* Indicates clickable area */
}

.notification-icon {
  font-size: 40px;
  transition: transform 0.3s; /* Smooth transition effect */
}

.notification-wrapper:hover .notification-icon {
  transform: scale(1.1); /* Slight scaling on hover */
}

.notifPlacement {
  margin-right: 20px;
  margin-top:0px !important;
}
.notification-count {
  position: absolute;
  top: -5px; /* Adjust positioning to prevent overlap */
  right: -5px;
  background-color: #d9534f;
  color: white;
  border-radius: 50%;
  min-width: 20px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow for depth */
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

@media (max-width: 768px) {
  /* Ajuster la taille selon le besoin */
  .text-to-hide {
    display: none;
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: none !important;
}
</style>
