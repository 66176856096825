<template>
  <div class="menu-content">
    <div class="legend">
      <span class="legend-item legend-blue" @click="filterUsers('cabinet')">
        <font-awesome-icon icon="user" class="legend-icon" />En séance
      </span>
      <span class="legend-item legend-purple" @click="filterUsers('licence')">
        <font-awesome-icon icon="user" class="legend-icon" /> À distance
      </span>
      <span class="legend-item legend-reset" @click="resetFilter" v-if="filter">
        <font-awesome-icon icon="user" class="legend-icon" /> Tous
      </span>
    </div>
    <ul class="user-list">
      <div v-for="user in filteredUsers" :key="user.id">
        <li
          class="user-item"
          @click="selectUserMenu(user)"
          :class="{
            active: selectedUser?.id === user.id || $IdPatient === user.id,
          }"
        >
          <font-awesome-icon
            class="user-icon"
            :icon="['fas', 'user']"
            :class="{
              'user-icon-blue': user.get('type_pratique') === 'cabinet',
              'user-icon-purple': user.get('type_pratique') === 'licence',
            }"
          />
          <span class="user-text">
            {{ user.get("firstname") + " " + user.get("lastname") }}
          </span>
        </li>
        <UserActionMenu
          v-if="selectedUser && selectedUser.id === user.id"
          :selectedUser="selectedUser"
          @action="handleUserAction"
        />
      </div>
    </ul>
  </div>
</template>
<script>
import UserActionMenu from "./UserActionMenu.vue";
export default {
  data() {
    return {
      filter: null,
    };
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    selectedUser: {
      type: [Object, String],
      required: true,
    },
    searchQuery: {
      type: String,
      default: "",
    },
  },
  components: {
    UserActionMenu,
  },
  computed: {
    filteredUsers() {
      return this.users.filter((user) => {
        if (this.filter) {
          return user.get("type_pratique") === this.filter;
        }
        return user
          .get("firstname")
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    },
  },
  watch: {
    $IdPatient: {
      handler: function (newValue) {
        console.log("IdPatient changed", newValue);
      },
      immediate: true,
    },
    selectedUser: {
      handler: function (newValue) {
        console.log("selectedUser changed", newValue);
      },
      immediate: true,
    },
  },
  methods: {
    handleUserAction(name,action) {
      console.log("Action", name, action);
      if (name === "follow") {
        this.$emit("suivi", action);
      } else if (name === "edit") {
        this.$emit("edit", action);
      } else if (name === "activity") {
        console.log('Activity',name,action);
        this.selectUser(action);
      }
    },
    filterUsers(type) {
      this.filter = type; // Met à jour le filtre en fonction du type sélectionné
    },
    resetFilter() {
      this.filter = null; // Réinitialise le filtre pour afficher tous les utilisateurs
    },
    selectUser(user) {
      this.$emit("select-user", user);
    },
    selectUserMenu(user) {
      if (this.selectedUser && this.selectedUser.id === user.id) {
        this.$emit("select-user-menu", null);
      } else {
        this.$emit("select-user-menu", user);
      }
    },
    recupId(id) {
      console.log("id", id);
      console.log("this.$IdPatient", this.$IdPatient);
      console.log("this.selectedUser", this.selectedUser);
    },
  },
  mounted() {
    console.log("users in user-list-vertical", this.users);
  },
};
</script>
<style scoped>
.menu-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.user-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-item {
  padding: 12px 16px;
  margin: 8px 0;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  color: #555;
  font-weight: 500;
  background-color: #f8f9fa;
}

.user-item:hover {
  background-color: #e9ecef;
  transform: translateX(5px);
}

.user-item.active {
  background-color: #239380;
  color: white;
  box-shadow: 0 2px 10px rgba(35, 147, 128, 0.3);
}

.user-item.active:hover {
  background-color: #1a7d6c;
}

.user-icon {
  margin-right: 12px;
  font-size: 1.1rem;
  color: #239380;
  transition: color 0.3s;
}

.user-item.active .user-icon {
  color: white;
}
/* Media queries pour la responsivité */
@media (max-width: 768px) {
  .user-menu {
    max-width: 100%;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }

  .menu-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .menu-title {
    margin: 0;
  }

  .user-item {
    padding: 10px 14px;
  }

  .user-text {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .menu-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-title {
    margin-bottom: 10px;
  }

  .user-item {
    padding: 8px 12px;
  }

  .user-icon {
    font-size: 1rem;
  }

  .user-text {
    font-size: 0.85rem;
  }
}
.user-text {
  display: inline-block;
  max-width: 200px; /* Ajuste cette valeur selon ton design */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.user-icon-blue {
  color: #4da0ff;
}

.user-icon-purple {
  color: #b83d99;
}

/* Style pour la légende */
.legend {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 0.9em;
  color: #555;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}

.legend-icon {
  margin-right: 5px;
}

/* Couleurs des icônes dans la légende */
.legend-blue .legend-icon {
  color: blue;
}

.legend-purple .legend-icon {
  color: purple;
}

/* Couleurs des icônes pour les utilisateurs */
.user-icon-blue {
  color: blue;
}

.user-icon-purple {
  color: purple;
}

</style>
