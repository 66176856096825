<template>
    <div class="info-bar">
      <div class="info-content">
        <span class="icon-info">i</span>
        <p>
          <strong>Sélection des briques :</strong> Cliquez sur une brique pour la sélectionner, cela permet de donner accès à votre utilisateur aux programmes et ressources que vous cochez.
        </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "InfoBar",
  };
  </script>
  
  <style scoped>
 /* Corps principal */
.info-bar {
  background-color: #239380; /* Couleur principale */
  color: #fff; /* Texte blanc pour un bon contraste */
  padding: 15px 20px; /* Ajout de padding pour espacer le contenu */
  border-radius: 8px; /* Coins arrondis pour un effet plus moderne */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre douce pour donner du relief */
  display: flex;
  align-items: center; /* Alignement vertical centré */
  transition: background-color 0.3s ease; /* Transition douce */
  margin-top: 20px; /* Marge en haut pour éviter que le contenu ne soit collé */
  margin-bottom:10px;
}


.info-content {
  display: flex;
  align-items: center;
  gap: 10px; /* Espacement entre l'icône et le texte */
}

.icon-info {
  background-color: #fff; /* Cercle blanc autour de l'icône pour attirer l'attention */
  color: #239380; /* Couleur principale pour l'icône */
  font-size: 20px; /* Taille de l'icône */
  font-weight: bold; /* Style de l'icône pour la rendre visible */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Rendre l'icône circulaire */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Petite ombre pour donner du relief à l'icône */
}

.info-bar p {
  margin: 0; /* Supprimer les marges du paragraphe */
  font-size: 16px; /* Taille du texte un peu plus grande */
  line-height: 1.5; /* Espacement agréable entre les lignes */
}

.info-bar p strong {
  font-weight: bold; /* Mettre en gras le texte important */
  color: #fff; /* Texte blanc pour un bon contraste */
}

@media (max-width: 768px) {
  .info-bar {
    flex-direction: column; /* Pour les petits écrans, aligner les éléments verticalement */
    text-align: center; /* Centrer le texte */
  }
  .info-content {
    gap: 5px; /* Réduire l'espacement sur mobile */
  }
}

  </style>
  