<template>
  <div>
    <InformationAttribution />
    <BriqueComponent
      :availableFoldersProgrammes="availableFoldersProgrammes[0].folders"
      v-if="availableFoldersProgrammes.length"
      key="1"
      title="Programmes"
    />
    <BriqueComponent
      :availableFoldersProgrammes="availableFolderRessourcesTotal[0].folders"
      v-if="availableFolderRessourcesTotal.length"
      key="2"
      title="Ressources Libres"
    />
    <BriqueComponent
      :availableFoldersProgrammes="availableFolderEchellesTotal[0].folders"
      v-if="availableFolderEchellesTotal.length"
      key="3"
      title="Échelles d'évaluation"
    />
  </div>
</template>
<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import BriqueComponent from "./BriqueComponent.vue";
import InformationAttribution from "./InformationAttribution.vue";
import { color } from "@amcharts/amcharts5";

export default {
  data() {
    return {
      availableFoldersProgrammes: [],
      availableFoldersRessources: [],
      availableFolderEchelles: [],
      availableFolderEchellesTotal: [
        {
          name: "Échelles d'évaluation",
          folders: [],
        },
      ],
      availableFolderRessourcesTotal: [
        {
          name: "Ressources Libres",
          folders: [],
        },
      ],
    };
  },
  components: {
    BriqueComponent,
    InformationAttribution,
  },
  async created() {
    console.log("%cCreated attributionsBrique", "color: green;");
    ParseConfig();

    try {
      // Appelle la fonction Parse Cloud que nous avons définie
      const response = await Parse.Cloud.run("getAvailableFoldersWithFolders");

      console.log(
        "%cAvailableFolders and folders found",
        "color: green;",
        response
      );

      // Mapping des noms d'AvailableFolders vers leurs tableaux correspondants
      const folderMap = {
        Programmes: this.availableFoldersProgrammes,
        "Ressources libres": this.availableFoldersRessources,
        "Échelles d'évaluation": this.availableFolderEchelles,
      };

      // Parcourir les résultats et assigner directement aux tableaux correspondants
      response.forEach((entry) => {
        const availableFolder = entry.availableFolder;
        console.log("EntryFolder:", entry.folders);
        const folders = entry.folders.map((folder) => ({
          id: folder.objectId,
          name: folder.name,
          resource: folder.resourceBg ? folder.resourceBg : null,
          color: folder.color ? folder.color : null,
          text: folder.text ? folder.text : "",
          videos: folder.videosIntro ? folder.videosIntro : [],
        }));

        const folderEntry = {
          name: availableFolder.name,
          folders: folders,
        };
        console.log("FolderEntry:", availableFolder.name);
        // Ajouter au bon tableau en fonction du nom d'AvailableFolder
        if (folderMap[availableFolder.name]) {
          folderMap[availableFolder.name].push(folderEntry);
        }
      });

      // Afficher les données pour vérification
      console.log(
        "AvailableFolders Programmes:",
        this.availableFoldersProgrammes
      );
      console.log(
        "AvailableFolders Ressources:",
        this.availableFoldersRessources
      );
      console.log("AvailableFolders Echelles:", this.availableFolderEchelles);

      Parse.Cloud.run("getFolderLinks", {
        availableFolders: this.availableFolderEchelles,
      })
        .then((responseEchelles) => {
          console.log(
            "Response from cloud function for Echelles:",
            responseEchelles
          );

          // Vérifier que la réponse contient des données
          if (Array.isArray(responseEchelles)) {
            // Traitement des dossiers d'échelles
            responseEchelles.forEach((entry) => {
              console.log(entry);
              const foldersEchelles = {
                id: entry.id,
                name: entry.attributes.name,
                resource: entry.attributes.resourceBg || null,
                color: entry.attributes.color
                  ? color(entry.attributes.color)
                  : null,
                text: entry.attributes.text || "",
                videos: entry.attributes.videosIntro || [],
              };

              // Ajout du dossier d'échelles si valide
              this.availableFolderEchellesTotal[0].folders.push(
                foldersEchelles
              );
            });
          } else {
            console.warn("No valid data returned for Echelles");
          }

          // Appel pour obtenir les dossiers de ressources
          return Parse.Cloud.run("getFolderLinks", {
            availableFolders: this.availableFoldersRessources,
          });
        })
        .then((responseRessources) => {
          console.log(
            "Response from cloud function for Ressources:",
            responseRessources
          );

          // Vérifier que la réponse contient des données
          if (Array.isArray(responseRessources)) {
            // Traitement des dossiers de ressources
            responseRessources.forEach((entry) => {
              console.log(entry);
              const foldersRessources = {
                id: entry.id,
                name: entry.attributes.name,
                resource: entry.attributes.resourceBg || null,
                color: entry.attributes.color
                  ? (() => {
                      const match = entry.attributes.color.match(
                        /hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/
                      );
                      if (match) {
                        const [h, s, l] = match.slice(1).map(Number);
                        const [r, g, b] = this.hslToRgb(h, s, l);
                        return color(`rgb(${r}, ${g}, ${b})`);
                      }
                      return color(entry.attributes.color);
                    })()
                  : null,
                text: entry.attributes.text || "",
                videos: entry.attributes.videosIntro || [],
              };

              // Ajout du dossier de ressources si valide
              this.availableFolderRessourcesTotal[0].folders.push(
                foldersRessources
              );
            });
          } else {
            console.warn("No valid data returned for Ressources");
          }
        })
        .catch((error) => {
          console.error("Error calling cloud function:", error);
        });
    } catch (error) {
      console.error(
        "Error while fetching AvailableFolders and folders from Cloud",
        error
      );
    }
  },
  methods: {
    hslToRgb(h, s, l) {
      h /= 360;
      s /= 100;
      l /= 100;
      let r, g, b;

      if (s === 0) {
        r = g = b = l;
      } else {
        const hue2rgb = (p, q, t) => {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
      }

      return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    },
  },
};
</script>
