import { render, staticRenderFns } from "./UserActionMenu.vue?vue&type=template&id=4c5f593f&scoped=true"
import script from "./UserActionMenu.vue?vue&type=script&lang=js"
export * from "./UserActionMenu.vue?vue&type=script&lang=js"
import style0 from "./UserActionMenu.vue?vue&type=style&index=0&id=4c5f593f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c5f593f",
  null
  
)

export default component.exports